import { useDebounce } from "$hooks/useDebounce";
import { api } from "$lib/api";
import { STALE_TIME } from "$lib/reactQuery";
import { SearchResult } from "$lib/search";
import { mdiMagnify } from "@mdi/js";
import Icon from "@mdi/react";
import { useCombobox } from "downshift";
import { KeyboardEventHandler, useMemo, useState } from "react";
import { SearchDropdown } from "./SearchDropdown";

export function NavSearch() {
  const [search, setSearch] = useState("");
  const debounceSearchString = useDebounce(search);

  const { data, isFetching } = api.search.globalSearch.useQuery(
    { searchString: debounceSearchString },
    {
      enabled: debounceSearchString.length > 0,
      staleTime: STALE_TIME.LONG,
      keepPreviousData: debounceSearchString.length > 0,
    }
  );

  // This value has to be memoized
  const results = useMemo(() => {
    return data ?? [];
  }, [data]);

  const {
    isOpen,
    highlightedIndex,
    getLabelProps,
    getInputProps,
    getMenuProps,
    getItemProps,
    openMenu,
  } = useCombobox<SearchResult>({
    id: "nav-main-search",
    items: results,
    selectedItem: null,
    itemToString(item) {
      return item ? item.name : "";
    },
    onStateChange: (changes) => {
      switch (changes.type) {
        case useCombobox.stateChangeTypes.InputChange:
          setSearch(changes.inputValue || "");
          break;
      }
    },
  });

  const onKeyDownCapture: KeyboardEventHandler = (e) => {
    let results = e.currentTarget.querySelectorAll(".navbar-search-result");
    if (e.key === "Enter") {
      if (!isOpen) {
        openMenu();
        e.preventDefault();
        e.stopPropagation();
      } else if (results[highlightedIndex]) {
        (results[highlightedIndex] as HTMLElement).click();
      }
    }
  };

  const inputProps = getInputProps();

  return (
    <div
      className="relative flex w-[250px] min-w-[140px] shrink items-center border-r border-anvil-100 pl-2 pr-2 tablet:pr-4"
      onKeyDownCapture={onKeyDownCapture}
    >
      <label className="m-0" htmlFor="search-input" {...getLabelProps()}>
        <span className="sr-only">Search</span>
        <Icon className="m-0 h-4 tablet:h-5" path={mdiMagnify} />
      </label>
      <input
        aria-label="main search"
        autoCorrect="off"
        autoCapitalize="off"
        className="nav-control ml-2 h-5 w-full border border-anvil-100 px-0.5 text-xs shadow-none"
        {...inputProps}
      />
      <SearchDropdown
        open={isOpen}
        results={results}
        loading={isFetching}
        searchString={debounceSearchString}
        getMenuProps={getMenuProps}
        getItemProps={getItemProps}
        highlightedIndex={highlightedIndex}
      />
    </div>
  );
}

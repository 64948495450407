type FileSizeUnit =
  | "kb"
  | "mb"
  | "gb"
  | "b"
  | "k"
  | "m"
  | "g"
  | "KB"
  | "MB"
  | "GB"
  | "B"
  | "K"
  | "M"
  | "G";
export type FileSize =
  | number
  | `${number}${FileSizeUnit}`
  | `${number} ${FileSizeUnit}`;
const fileSizeUnitConversion = {
  b: 1,
  k: 1024,
  m: 1024 * 1024,
  g: 1024 * 1024 * 1024,
};

export function fileSizeToBytes(fileSize: FileSize): number {
  if (typeof fileSize === "number") return fileSize;
  const match = fileSize.match(/(\d+)\s?([kmg]b?)?/i);
  if (!match) return parseInt(fileSize) || 0;
  const num = parseInt(match[1]);
  const unit = (match[2]?.[0].toLowerCase() ?? "b") as "b" | "k" | "m" | "g";
  return num * fileSizeUnitConversion[unit];
}

export function bytesToFileSize(
  bytes: number,
  decimalPlaces: number
): FileSize {
  for (const unit of ["g", "m", "k", "b"] as const) {
    const num = bytes / fileSizeUnitConversion[unit];
    if (num >= 1) {
      const unitStr = unit === "b" ? "B" : `${unit.toUpperCase()}B`;
      return `${toFixedTrimTrailingZeros(num, decimalPlaces)} ${unitStr}` as FileSize;
    }
  }
  return "0 B";
}

function toFixedTrimTrailingZeros(num: number, decimalPlaces: number): string {
  return num.toFixed(decimalPlaces).replace(/\.0*$|(\.[0-9]*[1-9])0*$/, "$1");
}

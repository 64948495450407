import { Ref, SVGProps, forwardRef } from "react";

const SvgComponent = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => {
  const { fill } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 30 12"
      width="30"
      height="12"
      fill={fill ?? "currentColor"}
      ref={ref}
      {...props}
    >
      <path d="M19.5 12C18.2217 12 17.5539 10.5221 18.3252 9.56682L18.4393 9.43998L21.876 6.00249C19.9721 4.19828 16.9525 3.00225 13.5 3.00225C7.62258 3.00225 3 6.46832 3 10.5004C3 11.3286 2.32843 12 1.5 12C0.671574 12 0 11.3286 0 10.5004C0 4.59379 6.12258 0.00300627 13.5 0.00300627C17.71 0.00300627 21.5114 1.49804 23.9995 3.87947L27.4393 0.442236C28.3432 -0.4614 29.8608 0.111477 29.991 1.33223L30 1.50263V10.5004C30 11.2694 29.4209 11.9033 28.6749 11.9899L28.5 12H19.5Z" />
    </svg>
  );
};

const RedoArrowIcon = forwardRef(SvgComponent);
RedoArrowIcon.displayName = "RedoArrowIcon";
export { RedoArrowIcon };


import { env } from "$env";

export function getRootSF3URL() {
  let root = env.NEXT_PUBLIC_APP_URL.replace("/v4", "");

  if (root?.includes("sf.hcos.test")) {
    root += "/app_dev.php";
  }

  return root;
}

export type SF4Pages = {
  base: string;
  params?: Record<string, string | number | undefined>;
} & (
  | {
      base: "/construct/quiz/[quizId]";
      params: {
        quizId: number;
        tab: "builder" | "settings" | "history";
        group?: number;
      };
    }
  | {
      base: "/construct/quiz/[quizId]/preview";
      params: {
        quizId: number;
        group?: number;
        version?: number;
      };
    }
  | { base: "/manage/quiz/bulksettings" }
  | { base: "/manage/quiz/presets" }
  | {
      base: "manage/regions/[id]";
      params: {
        id: number;
      };
    }
  | {
      base: "/construct/quiz/[quizId]/institutionsettings/[institutionId]";
      params: {
        quizId: number;
        institutionId: number;
      };
    }
  | {
      base: "/embed/interactive/[id]";
      params: {
        id: number;
      };
    }
);

export function getSF4URL(page: SF4Pages) {
  return env.NEXT_PUBLIC_APP_URL + getSF4Path(page);
}

export function getSF4Path(page: SF4Pages) {
  let path: string = page.base;

  const searchParams = new URLSearchParams();

  const params = page.params;
  if (!params) {
    return path;
  }

  for (const [key, value] of Object.entries(params)) {
    if (value === undefined) {
      continue;
    }

    if (path.includes(`[${key}]`)) {
      path = path.replace(`[${key}]`, String(value));
    } else {
      searchParams.append(key, String(value));
    }
  }

  const searchParamsStr = searchParams.toString();
  return path + (searchParamsStr ? `?${searchParamsStr}` : "");
}

export function getLoginUrl(forwardURL: string | null = null) {
  return (
    getRootSF3URL() +
    "/login" +
    (typeof forwardURL === "string"
      ? `?forward_after_login=${encodeURIComponent(
          process.env.NEXT_PUBLIC_APP_URL + forwardURL
        )}`
      : "")
  );
}

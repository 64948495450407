import { env } from "$env";
import { UserSelection } from "$hooks/useUserSelection";
import type { UserContextSearchResult } from "$server/data/search/userContextSearch";
import { RouterOutputs } from "../api";
import { getRootSF3URL } from "./routing";

type BaseMenuPermission =
  RouterOutputs["navbar"]["loadPermissions"]["permissions"][number];

const rootSF3URL = getRootSF3URL();

export const getBaseMenuItems = ({
  institutionId,
}: {
  institutionId: number | null;
}): Array<{
  title: string;
  list: Array<{
    permission: BaseMenuPermission | null;
    name: string;
    href: string;
    btnStyle?: true;
    studentOnly?: true;
    nonStudentOnly?: true;
  }>;
}> => [
  {
    title: "Customization",
    list: [
      {
        permission: "myCustomCourses",
        studentOnly: true,
        name: "My Custom Courses",
        href: rootSF3URL + "/construct/courses",
      },
    ],
  },
  {
    title: "Manage",
    list: [
      {
        permission: null,
        nonStudentOnly: true,
        name: "Account Settings",
        href: rootSF3URL + `/accountsettings/profile`,
      },
      {
        permission: "joinGroup",
        name: "Join a Group",
        href: rootSF3URL + "/join",
        btnStyle: true,
      },
      {
        permission: "myCustomCourses",
        nonStudentOnly: true,
        name: "My Custom Courses",
        href: rootSF3URL + "/construct/courses",
      },
      {
        permission: "manageMyGroups",
        name: "Manage My Groups",
        href: rootSF3URL + "/manage/my/groups",
      },
      {
        permission: "manageAuthors",
        name: "Manage Authors",
        href: env.NEXT_PUBLIC_APP_URL + "/manage/authors",
      },
      {
        permission: "manageBibleVersions",
        name: "Manage Bible Versions",
        href: env.NEXT_PUBLIC_APP_URL + "/manage/bibleversions",
      },
      {
        permission: "manageCSSThemes",
        name: "Manage CSS Themes",
        href: env.NEXT_PUBLIC_APP_URL + "/manage/cssthemes",
      },
      {
        permission: "manageCourses",
        name: "Manage Courses",
        href: rootSF3URL + "/construct/courses",
      },
      {
        permission: "manageLicenseTransactions",
        name: "Manage License Transactions",
        href: rootSF3URL + `/manage/institution/${institutionId}/credits`,
      },
      {
        permission: "manageMyCustomCourses",
        name: "Manage My Custom Courses",
        href: rootSF3URL + "/construct/courses",
      },
      {
        permission: "manageExternalTools",
        name: "Manage External Tools",
        href: env.NEXT_PUBLIC_APP_URL + "/manage/externaltools",
      },
      {
        permission: "manageFeatureFlags",
        name: "Manage Feature Flags",
        href: env.NEXT_PUBLIC_APP_URL + "/manage/featureflags",
      },
      {
        permission: "manageInstitutions",
        name: "Manage Institutions",
        href: env.NEXT_PUBLIC_APP_URL + "/manage/institutions",
      },
      {
        permission: "manageLTIRegistrations",
        name: "Manage LTI Registrations",
        href: rootSF3URL + "/manage/lti/registrations",
      },
      {
        permission: "manageLearningObjects",
        name: "Manage Learning Objects",
        href: env.NEXT_PUBLIC_APP_URL + "/manage/learningobjects",
      },
      {
        permission: "managePartners",
        name: "Manage Partners",
        href: env.NEXT_PUBLIC_APP_URL + "/manage/partners",
      },
      {
        permission: "managePermissions",
        name: "Manage Permissions",
        href: rootSF3URL + "/admin/permission",
      },
      {
        permission: "manageQuizQuestions",
        name: "Manage Quiz Questions",
        href: rootSF3URL + "/manage/items",
      },
      {
        permission: "manageQuizSettingsBulkApply",
        name: "Manage Quiz Settings - Bulk Apply",
        href: env.NEXT_PUBLIC_APP_URL + "/manage/quiz/bulksettings",
      },
      {
        permission: "manageQuizSettingsPresets",
        name: "Manage Quiz Settings - Presets",
        href: env.NEXT_PUBLIC_APP_URL + "/manage/quiz/presets",
      },
      {
        permission: "manageRegions",
        name: "Manage Regions",
        href: env.NEXT_PUBLIC_APP_URL + "/manage/regions",
      },
      {
        permission: "manageRoleAssignments",
        name: "Manage Role Assignments",
        href: rootSF3URL + "/manage/roleassignments",
      },
      {
        permission: "manageRoles",
        name: "Manage Roles",
        href: env.NEXT_PUBLIC_APP_URL + "/manage/roles",
      },
      {
        permission: "manageUserGroups",
        name: "Manage User Groups",
        href: rootSF3URL + "/manage/groups",
      },
      {
        permission: "manageUsers",
        name: "Manage Users",
        href: rootSF3URL + "/manage/users",
      },
      {
        permission: "manageMyStudentGroups",
        name: "Manage My Student Groups",
        href: rootSF3URL + "/manage/my/groups",
      },
      {
        permission: "viewCourseStreams",
        name: "View Course Streams",
        href: rootSF3URL + "/construct/coursestreams",
      },
    ],
  },
  {
    title: "Create",
    list: [
      {
        permission: "createNewInteractive",
        name: "Create New Interactive",
        href: rootSF3URL + "/interactive/new",
      },
      {
        permission: "createNewQuestion",
        name: "Create New Question",
        href: rootSF3URL + "/question/new",
      },
      {
        permission: "createNewQuizQuestions",
        name: "Create New Quiz Question",
        href: rootSF3URL + "/item/create",
      },
      {
        permission: "createNewReading",
        name: "Create New Reading",
        href: rootSF3URL + "/reading/new",
      },
      {
        permission: "createNewVideo",
        name: "Create New Video",
        href: rootSF3URL + "/video/new",
      },
    ],
  },
  {
    title: "Report",
    list: [
      {
        permission: "viewInternalReports",
        name: "View Internal Reports",
        href: rootSF3URL + "/internalreports",
      },
      {
        permission: "viewReportDashboard",
        name: "View Report Dashboard",
        href: rootSF3URL + "/report/dashboard",
      },
    ],
  },
];

/**
 * Transform the results from the "searchViewAs" tRPC so it can be used in the "User View As" dropdown
 * The results are originally sorted by relevancy (from the tRPC request)
 * Results should be grouped by type, but the type with the highest relevancy should be at the top.
 */
export function transformViewAsSearchData(
  originalArray: UserContextSearchResult[] | undefined
): UserSelection["data"] {
  if (!originalArray) {
    return null;
  }
  // Create an object to store transformed items
  const transformedArray: UserSelection["data"] = [];

  originalArray.forEach((item) => {
    const arrayItem = transformedArray.find((e) => e.title === item.type);

    if (arrayItem) {
      arrayItem.list = arrayItem.list.concat([{ ...item, fromSearch: true }]);
    } else {
      transformedArray.push({
        title: item.type,
        list: [{ ...item, fromSearch: true }],
      });
    }
  });

  return transformedArray;
}

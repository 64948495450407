import { Ref, SVGProps, forwardRef } from "react";

const SvgComponent = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 22 16"
    width="22"
    fill="currentColor"
    ref={ref}
    {...props}
  >
    <path d="M17.2694 9.63279V6.44431H14.0924V4.88514H17.2694V1.69669H18.823V4.88514H22V6.44431H18.823V9.63279H17.2694ZM8.05413 7.73663C6.92269 7.73663 5.99762 7.37599 5.27894 6.65472C4.56025 5.93344 4.20091 5.00504 4.20091 3.86952C4.20091 2.73401 4.56025 1.80521 5.27894 1.08313C5.99762 0.361042 6.92269 0 8.05413 0C9.18556 0 10.112 0.361042 10.8335 1.08313C11.5549 1.80521 11.9157 2.73401 11.9157 3.86952C11.9157 5.00504 11.5549 5.93344 10.8335 6.65472C10.112 7.37599 9.18556 7.73663 8.05413 7.73663ZM0 16V13.5086C0 12.9011 0.152411 12.3513 0.457232 11.8591C0.762053 11.367 1.19734 10.997 1.7631 10.7494C3.00653 10.1998 4.11363 9.8124 5.08439 9.58723C6.05516 9.36205 7.04316 9.24945 8.04838 9.24945C9.06116 9.24945 10.0512 9.36205 11.0185 9.58723C11.9859 9.8124 13.0893 10.1998 14.3288 10.7494C14.8945 11.009 15.3339 11.3808 15.647 11.8646C15.96 12.3484 16.1166 12.896 16.1166 13.5073V16H0ZM1.68358 14.3104H14.4271V13.5324C14.4271 13.2702 14.3528 13.0203 14.2042 12.7825C14.0557 12.5448 13.8576 12.3686 13.61 12.2539C12.4611 11.7206 11.487 11.3692 10.6876 11.1995C9.88825 11.0299 9.00984 10.9451 8.05238 10.9451C7.10278 10.9451 6.2232 11.0299 5.41364 11.1995C4.60408 11.3692 3.62516 11.7206 2.47688 12.2537C2.22807 12.3683 2.03365 12.5445 1.89362 12.7823C1.75359 13.0202 1.68358 13.2702 1.68358 13.5324V14.3104ZM8.05512 6.04699C8.68594 6.04699 9.20547 5.84143 9.61374 5.4303C10.022 5.0192 10.2262 4.4986 10.2262 3.86853C10.2262 3.23683 10.0221 2.71577 9.61396 2.30534C9.20583 1.89489 8.68734 1.68967 8.05848 1.68967C7.42965 1.68967 6.91011 1.89498 6.49987 2.30561C6.08962 2.71622 5.88449 3.23622 5.88449 3.8656C5.88449 4.4966 6.08931 5.01791 6.49896 5.42953C6.90859 5.84117 7.42731 6.04699 8.05512 6.04699Z" />
  </svg>
);

const AddUserIcon = forwardRef(SvgComponent);
AddUserIcon.displayName = "AddUserIcon";
export { AddUserIcon };

import { Dialog } from "radix-ui";
import { ReactElement, cloneElement, isValidElement } from "react";
import { tv } from "tailwind-variants";
import { useModal } from "../../context/modal/ModalContext";
import { Button } from "./Button";
import { CloseIcon } from "./Icons/CloseIcon";

type CommonModalProps = {
  content: React.ReactNode;
  className?: string;
};

export type DialogModalProps = {
  title: string;
  type: "dialog";
  showConfirm?: boolean;
  onConfirmLabel?: string;
  onConfirm?: Function;
  showDismiss?: boolean;
  onDismissLabel?: string;
  showClose?: boolean;
} & CommonModalProps;

export type AlertModalProps = {
  title: string;
  type: "alert";
  onDismissLabel?: string;
} & CommonModalProps;

export type ModalProps = {
  title?: string;
  type: "modal";
  showClose?: boolean;
} & CommonModalProps;

export type ModalContentProps = {
  id: string;
} & (DialogModalProps | AlertModalProps | ModalProps);

const variants = tv({
  slots: {
    dialog:
      "fixed inset-1/2 z-50 flex max-h-[100vh] min-h-min w-full max-w-lg -translate-x-1/2 -translate-y-1/2 flex-col gap-4 rounded-xl border border-carbon-300 bg-carbon-50 px-6 pb-6 pt-4",
    modal:
      "fixed inset-1/2 z-50 flex h-fit max-h-[100vh] w-max max-w-[80vw] -translate-x-1/2 -translate-y-1/2 flex-col gap-4 overflow-auto rounded-xl border border-carbon-300 bg-carbon-50 px-6 pb-6 pt-4",
    title: "flex shrink-0 text-3xl font-bold text-carbon-900",
  },
});

function ModalContent(props: ModalContentProps) {
  const { remove } = useModal();

  const style = variants();
  const onDismiss = () => {
    remove(props.id);
  };

  return (
    <>
      {props.type === "dialog" ? (
        <Dialog.Content className={style.dialog({ class: props.className })}>
          {(props.showClose ?? false) && (
            <Dialog.Close asChild>
              <button
                type="button"
                className="absolute right-6 top-4 cursor-pointer"
                onClick={onDismiss}
              >
                <CloseIcon className="h-5 w-5" />
              </button>
            </Dialog.Close>
          )}
          <Dialog.Title className={style.title()}>{props.title}</Dialog.Title>
          {typeof props.content === "string" ? (
            <p className="text-base text-carbon-700">{props.content}</p>
          ) : (
            props.content
          )}
          <div className="flex items-center justify-center gap-x-4 pt-4">
            {(props.showDismiss ?? true) && (
              <Dialog.Close asChild>
                <Button
                  type="button"
                  variant="subtle"
                  size="lg"
                  onClick={onDismiss}
                  data-testid="dialog-dismiss-button"
                >
                  {props.onDismissLabel ?? "Cancel"}
                </Button>
              </Dialog.Close>
            )}
            {(props.showConfirm ?? true) && (
              <Button
                type="button"
                variant="primary"
                size="lg"
                onClick={() => {
                  props.onConfirm ? props.onConfirm() : null;
                  onDismiss();
                }}
                data-testid="dialog-confirm-button"
              >
                {props.onConfirmLabel ?? "Confirm"}
              </Button>
            )}
          </div>
        </Dialog.Content>
      ) : props.type === "alert" ? (
        <Dialog.Content className={style.dialog({ class: props.className })}>
          <Dialog.Title className={style.title()}>{props.title}</Dialog.Title>
          {typeof props.content === "string" ? (
            <p className="text-base text-carbon-700">{props.content}</p>
          ) : (
            props.content
          )}
          <div className="flex items-center justify-center pt-4">
            <Dialog.Close asChild>
              <Button
                type="button"
                size="lg"
                onClick={onDismiss}
                data-testid="alert-dismiss-button"
              >
                {props.onDismissLabel ?? "OK"}
              </Button>
            </Dialog.Close>
          </div>
        </Dialog.Content>
      ) : (
        <Dialog.Content className={style.modal({ class: props.className })}>
          {!!props.title && (
            <Dialog.Title className={style.title()}>{props.title}</Dialog.Title>
          )}
          {!!props.showClose && (
            <Dialog.Close asChild>
              <button
                type="button"
                className="absolute right-6 top-4 cursor-pointer"
                onClick={onDismiss}
              >
                <CloseIcon className="h-5 w-5" />
              </button>
            </Dialog.Close>
          )}
          {isValidElement(props.content)
            ? cloneElement(props.content as ReactElement<any>, {
                modal_id: props.id,
              })
            : props.content}
        </Dialog.Content>
      )}
    </>
  );
}

export { ModalContent };

import { Ref, SVGProps, forwardRef } from "react";

const SvgComponent = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 25 25"
      width="25"
      stroke="currentColor"
      fill="none"
      ref={ref}
      {...props}
    >
      <polyline points="1.9,7 1.9,23.6 23,23.6 23,7 			" />
      <polygon points="24.8,7 12.5,0.7 0.2,7 			" />
      <rect x="11.1" y="12.5" width="2.6" height="6.8" />
      <line x1="0.2" y1="9.4" x2="24.8" y2="9.4" />
      <line x1="5.4" y1="9.4" x2="5.4" y2="23.6" />
      <line x1="8" y1="9.4" x2="8" y2="23.6" />
      <line x1="19.6" y1="9.4" x2="19.6" y2="23.6" />
      <line x1="16.9" y1="9.4" x2="16.9" y2="23.6" />
      <line x1="1.9" y1="21.5" x2="5.4" y2="21.5" />
      <line x1="19.6" y1="21.5" x2="23" y2="21.5" />
      <line x1="8" y1="19.4" x2="16.9" y2="19.4" />
      <line x1="8" y1="21.5" x2="16.9" y2="21.5" />
    </svg>
  );
};

const InstitutionIcon = forwardRef(SvgComponent);
InstitutionIcon.displayName = "InstitutionIcon";
export { InstitutionIcon };

import { useDebounce } from "$hooks/useDebounce";
import { useFetchFirstGlobalSearchPageAfterIds } from "$hooks/useFetchFirstGlobalSearchPageAfterIds";
import { api } from "$lib/api";
import { STALE_TIME } from "$lib/reactQuery";
import { isSearchEnabled } from "$lib/search";
import { mdiMagnify } from "@mdi/js";
import Icon from "@mdi/react";
import { useCombobox } from "downshift";
import { KeyboardEventHandler, useMemo, useState } from "react";
import { SearchDropdown, type SearchResult } from "./SearchDropdown";

export function NavSearch() {
  const [search, setSearch] = useState("");
  const debounceSearchString = useDebounce(search.trim());

  const {
    data: searchData,
    isFetching: isFetchingSearch,
    error: searchError,
    fetchNextPage,
  } = api.search.globalSearch.useInfiniteQuery(
    { searchString: debounceSearchString },
    {
      enabled: isSearchEnabled(debounceSearchString),
      keepPreviousData: isSearchEnabled(debounceSearchString),
      staleTime: STALE_TIME.LONG,
      getNextPageParam: (lastPage) => lastPage.nextCursor ?? undefined,
      meta: { preventNotification: true },
    }
  );

  void useFetchFirstGlobalSearchPageAfterIds({
    data: searchData,
    fetchNextPage,
  });

  // This value has to be memoized
  const results: SearchResult[] = useMemo(() => {
    return searchData?.pages.flatMap((p) => p.results) ?? [];
  }, [searchData]);

  const {
    isOpen,
    highlightedIndex,
    getLabelProps,
    getInputProps,
    getMenuProps,
    getItemProps,
    openMenu,
  } = useCombobox<SearchResult>({
    id: "nav-main-search",
    items: results,
    selectedItem: null,
    itemToString(item) {
      return item?.name ?? "";
    },
    stateReducer(state, actionAndChanges) {
      switch (actionAndChanges.type) {
        // Prevent the menu from closing when an item when pressing enter
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
          return {
            ...actionAndChanges.changes,
            isOpen: true,
          };

        default:
          return actionAndChanges.changes;
      }
    },
    onStateChange: (changes) => {
      switch (changes.type) {
        case useCombobox.stateChangeTypes.InputChange:
          setSearch(changes.inputValue || "");
          break;
      }
    },
  });

  const onKeyDownCapture: KeyboardEventHandler = (e) => {
    let results = e.currentTarget.querySelectorAll(".navbar-search-result");
    if (e.key === "Enter") {
      if (!isOpen) {
        openMenu();
        e.preventDefault();
        e.stopPropagation();
      } else if (results[highlightedIndex]) {
        (results[highlightedIndex] as HTMLElement).click();
      }
    }
  };

  const inputProps = getInputProps();

  return (
    <div
      className="relative flex w-[250px] min-w-[140px] shrink items-center border-r border-anvil-100 pl-2 pr-2 tablet:pr-4"
      onKeyDownCapture={onKeyDownCapture}
    >
      <label className="m-0" htmlFor="search-input" {...getLabelProps()}>
        <span className="sr-only">Search</span>
        <Icon className="m-0 h-4 tablet:h-5" path={mdiMagnify} />
      </label>
      <input
        aria-label="main search"
        autoCorrect="off"
        autoCapitalize="off"
        className="nav-control ml-2 h-5 w-full border border-anvil-100 px-0.5 text-xs shadow-none"
        {...inputProps}
      />
      <SearchDropdown
        open={isOpen}
        results={results}
        loading={isFetchingSearch}
        searchString={debounceSearchString}
        getMenuProps={getMenuProps}
        getItemProps={getItemProps}
        highlightedIndex={highlightedIndex}
        error={searchError?.message}
        loadNextSearchResults={fetchNextPage}
      />
    </div>
  );
}

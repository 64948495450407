import { Ref, forwardRef } from "react";
import { IconProps, IconWeight } from "./index";

const SvgComponent = (
  {
    weight = "regular",
    title,
    ...svgProps
  }: IconProps & {
    weight?: IconWeight;
  },
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="24"
    fill="currentColor"
    ref={ref}
    {...svgProps}
  >
    {title && <title>{title}</title>}
    <Content weight={weight} />
  </svg>
);

const Content = ({ weight }: { weight: IconWeight }) => {
  switch (weight) {
    case "thin":
      return (
        <path d="M19.0153 18.4847C19.0856 18.555 19.1252 18.6505 19.1252 18.75C19.1252 18.8495 19.0856 18.9449 19.0153 19.0153C18.9449 19.0856 18.8495 19.1252 18.75 19.1252C18.6505 19.1252 18.555 19.0856 18.4847 19.0153L12 12.5306L5.51528 19.0153C5.44491 19.0856 5.34948 19.1252 5.24996 19.1252C5.15045 19.1252 5.05502 19.0856 4.98465 19.0153C4.91429 18.9449 4.87476 18.8495 4.87476 18.75C4.87476 18.6505 4.91429 18.555 4.98465 18.4847L11.4693 12L4.98465 5.51528C4.91429 5.44491 4.87476 5.34948 4.87476 5.24996C4.87476 5.15045 4.91429 5.05502 4.98465 4.98465C5.05502 4.91429 5.15045 4.87476 5.24996 4.87476C5.34948 4.87476 5.44491 4.91429 5.51528 4.98465L12 11.4693L18.4847 4.98465C18.5195 4.94981 18.5609 4.92217 18.6064 4.90332C18.6519 4.88446 18.7007 4.87476 18.75 4.87476C18.7992 4.87476 18.848 4.88446 18.8936 4.90332C18.9391 4.92217 18.9804 4.94981 19.0153 4.98465C19.0501 5.01949 19.0778 5.06086 19.0966 5.10638C19.1155 5.1519 19.1252 5.20069 19.1252 5.24996C19.1252 5.29924 19.1155 5.34803 19.0966 5.39355C19.0778 5.43907 19.0501 5.48044 19.0153 5.51528L12.5306 12L19.0153 18.4847Z" />
      );

    case "light":
      return (
        <path d="M19.1475 18.3525C19.2028 18.404 19.2471 18.4661 19.2778 18.5351C19.3086 18.6041 19.3251 18.6786 19.3265 18.7541C19.3278 18.8296 19.3139 18.9047 19.2856 18.9747C19.2573 19.0447 19.2152 19.1084 19.1618 19.1618C19.1084 19.2152 19.0447 19.2573 18.9747 19.2856C18.9047 19.3139 18.8296 19.3278 18.7541 19.3265C18.6786 19.3251 18.6041 19.3086 18.5351 19.2778C18.4661 19.2471 18.404 19.2028 18.3525 19.1475L12 12.7959L5.64751 19.1475C5.54088 19.2469 5.39984 19.301 5.25411 19.2984C5.10839 19.2958 4.96935 19.2368 4.86629 19.1337C4.76323 19.0307 4.7042 18.8916 4.70163 18.7459C4.69905 18.6002 4.75315 18.4591 4.85251 18.3525L11.2041 12L4.85251 5.64751C4.75315 5.54088 4.69905 5.39984 4.70163 5.25411C4.7042 5.10839 4.76323 4.96935 4.86629 4.86629C4.96935 4.76323 5.10839 4.7042 5.25411 4.70163C5.39984 4.69905 5.54088 4.75315 5.64751 4.85251L12 11.2041L18.3525 4.85251C18.4591 4.75315 18.6002 4.69905 18.7459 4.70163C18.8916 4.7042 19.0307 4.76323 19.1337 4.86629C19.2368 4.96935 19.2958 5.10839 19.2984 5.25411C19.301 5.39984 19.2469 5.54088 19.1475 5.64751L12.7959 12L19.1475 18.3525Z" />
      );

    case "regular":
      return (
        <path d="M19.2807 18.2194C19.3504 18.2891 19.4056 18.3718 19.4433 18.4629C19.4811 18.5539 19.5005 18.6515 19.5005 18.7501C19.5005 18.8486 19.4811 18.9462 19.4433 19.0372C19.4056 19.1283 19.3504 19.211 19.2807 19.2807C19.211 19.3504 19.1283 19.4056 19.0372 19.4433C18.9462 19.4811 18.8486 19.5005 18.7501 19.5005C18.6515 19.5005 18.5539 19.4811 18.4629 19.4433C18.3718 19.4056 18.2891 19.3504 18.2194 19.2807L12.0001 13.0604L5.78068 19.2807C5.63995 19.4214 5.44907 19.5005 5.25005 19.5005C5.05103 19.5005 4.86016 19.4214 4.71943 19.2807C4.5787 19.1399 4.49963 18.9491 4.49963 18.7501C4.49963 18.551 4.5787 18.3602 4.71943 18.2194L10.9397 12.0001L4.71943 5.78068C4.5787 5.63995 4.49963 5.44907 4.49963 5.25005C4.49963 5.05103 4.5787 4.86016 4.71943 4.71943C4.86016 4.5787 5.05103 4.49963 5.25005 4.49963C5.44907 4.49963 5.63995 4.5787 5.78068 4.71943L12.0001 10.9397L18.2194 4.71943C18.3602 4.5787 18.551 4.49963 18.7501 4.49963C18.9491 4.49963 19.1399 4.5787 19.2807 4.71943C19.4214 4.86016 19.5005 5.05103 19.5005 5.25005C19.5005 5.44907 19.4214 5.63995 19.2807 5.78068L13.0604 12.0001L19.2807 18.2194Z" />
      );

    case "bold":
      return (
        <path d="M19.5459 17.9541C19.7572 18.1654 19.876 18.4521 19.876 18.751C19.876 19.0499 19.7572 19.3365 19.5459 19.5479C19.3346 19.7592 19.0479 19.8779 18.749 19.8779C18.4501 19.8779 18.1635 19.7592 17.9521 19.5479L12 13.5938L6.0459 19.546C5.83455 19.7573 5.54791 19.8761 5.24902 19.8761C4.95014 19.8761 4.66349 19.7573 4.45215 19.546C4.2408 19.3346 4.12207 19.048 4.12207 18.7491C4.12207 18.4502 4.2408 18.1636 4.45215 17.9522L10.4062 12L4.45402 6.04598C4.24268 5.83464 4.12395 5.54799 4.12395 5.2491C4.12395 4.95022 4.24268 4.66357 4.45402 4.45223C4.66537 4.24089 4.95201 4.12215 5.2509 4.12215C5.54978 4.12215 5.83643 4.24089 6.04777 4.45223L12 10.4063L17.954 4.45129C18.1654 4.23995 18.452 4.12122 18.7509 4.12122C19.0498 4.12122 19.3364 4.23995 19.5478 4.45129C19.7591 4.66264 19.8778 4.94928 19.8778 5.24817C19.8778 5.54705 19.7591 5.8337 19.5478 6.04504L13.5937 12L19.5459 17.9541Z" />
      );

    case "fill":
      return (
        <path d="M19.5 3H4.5C4.10218 3 3.72064 3.15804 3.43934 3.43934C3.15804 3.72064 3 4.10218 3 4.5V19.5C3 19.8978 3.15804 20.2794 3.43934 20.5607C3.72064 20.842 4.10218 21 4.5 21H19.5C19.8978 21 20.2794 20.842 20.5607 20.5607C20.842 20.2794 21 19.8978 21 19.5V4.5C21 4.10218 20.842 3.72064 20.5607 3.43934C20.2794 3.15804 19.8978 3 19.5 3ZM17.0306 15.9694C17.1003 16.0391 17.1556 16.1218 17.1933 16.2128C17.231 16.3039 17.2504 16.4015 17.2504 16.5C17.2504 16.5985 17.231 16.6961 17.1933 16.7872C17.1556 16.8782 17.1003 16.9609 17.0306 17.0306C16.9609 17.1003 16.8782 17.1556 16.7872 17.1933C16.6961 17.231 16.5985 17.2504 16.5 17.2504C16.4015 17.2504 16.3039 17.231 16.2128 17.1933C16.1218 17.1556 16.0391 17.1003 15.9694 17.0306L12 13.0603L8.03063 17.0306C7.88989 17.1714 7.69902 17.2504 7.5 17.2504C7.30098 17.2504 7.11011 17.1714 6.96937 17.0306C6.82864 16.8899 6.74958 16.699 6.74958 16.5C6.74958 16.301 6.82864 16.1101 6.96937 15.9694L10.9397 12L6.96937 8.03063C6.82864 7.88989 6.74958 7.69902 6.74958 7.5C6.74958 7.30098 6.82864 7.11011 6.96938 6.96938C7.11011 6.82864 7.30098 6.74958 7.5 6.74958C7.69902 6.74958 7.88989 6.82864 8.03063 6.96937L12 10.9397L15.9694 6.96937C16.1101 6.82864 16.301 6.74958 16.5 6.74958C16.699 6.74958 16.8899 6.82864 17.0306 6.96937C17.1714 7.11011 17.2504 7.30098 17.2504 7.5C17.2504 7.69902 17.1714 7.88989 17.0306 8.03063L13.0603 12L17.0306 15.9694Z" />
      );

    case "duotone":
      return (
        <>
          <path
            opacity="0.2"
            d="M20.25 4.5V19.5C20.25 19.6989 20.171 19.8897 20.0303 20.0303C19.8897 20.171 19.6989 20.25 19.5 20.25H4.5C4.30109 20.25 4.11032 20.171 3.96967 20.0303C3.82902 19.8897 3.75 19.6989 3.75 19.5V4.5C3.75 4.30109 3.82902 4.11032 3.96967 3.96967C4.11032 3.82902 4.30109 3.75 4.5 3.75H19.5C19.6989 3.75 19.8897 3.82902 20.0303 3.96967C20.171 4.11032 20.25 4.30109 20.25 4.5Z"
          />
          <path d="M19.2807 18.2194C19.3504 18.2891 19.4056 18.3718 19.4433 18.4629C19.4811 18.5539 19.5005 18.6515 19.5005 18.7501C19.5005 18.8486 19.4811 18.9462 19.4433 19.0372C19.4056 19.1283 19.3504 19.211 19.2807 19.2807C19.211 19.3504 19.1283 19.4056 19.0372 19.4433C18.9462 19.4811 18.8486 19.5005 18.7501 19.5005C18.6515 19.5005 18.5539 19.4811 18.4629 19.4433C18.3718 19.4056 18.2891 19.3504 18.2194 19.2807L12.0001 13.0604L5.78068 19.2807C5.63995 19.4214 5.44907 19.5005 5.25005 19.5005C5.05103 19.5005 4.86016 19.4214 4.71943 19.2807C4.5787 19.1399 4.49963 18.9491 4.49963 18.7501C4.49963 18.551 4.5787 18.3602 4.71943 18.2194L10.9397 12.0001L4.71943 5.78068C4.5787 5.63995 4.49963 5.44907 4.49963 5.25005C4.49963 5.05103 4.5787 4.86016 4.71943 4.71943C4.86016 4.5787 5.05103 4.49963 5.25005 4.49963C5.44907 4.49963 5.63995 4.5787 5.78068 4.71943L12.0001 10.9397L18.2194 4.71943C18.3602 4.5787 18.551 4.49963 18.7501 4.49963C18.9491 4.49963 19.1399 4.5787 19.2807 4.71943C19.4214 4.86016 19.5005 5.05103 19.5005 5.25005C19.5005 5.44907 19.4214 5.63995 19.2807 5.78068L13.0604 12.0001L19.2807 18.2194Z" />
        </>
      );
  }
};

const CloseIcon = forwardRef(SvgComponent);
CloseIcon.displayName = "CloseIcon";
export { CloseIcon };

import { TableFilter } from "$components/shared/Table/lib";
import { memoize } from "$lib/memoize";
import {
  type SearchEntity,
  SearchEntityType,
  SearchWhere,
  TermOrIdSchema,
} from "@studyforge/schemas/search";
import { addDays, getUnixTime, startOfDay } from "date-fns";
import { z } from "zod";

/**
 * This function takes an array of search result entities and a transformation function.
 * It groups the transformed results by their entity type into a map.
 * If the transformation function throws an error for a particular entity, that entity is ignored.
 *
 * @template T - The type to which the search results will be transformed.
 * @param  results - The array of search result entities to be grouped and transformed.
 * @param  transformFn - The function to transform each search result entity.
 * @param  typesFilter - Optional array of entity types to filter the results.
 * @returns  A map where the keys are entity types and the values are arrays of transformed search results.
 */
export function groupAndTransformSearchResults<
  T,
  R extends { entity_type: SearchEntity["entity_type"] },
>(
  results: R[],
  transformFn: (result: R) => T,
  typesFitler?: SearchEntity["entity_type"][]
) {
  const emptyMap: Record<SearchEntity["entity_type"], T[]> = {
    video: [],
    question: [],
    reading: [],
    interactive: [],
    lesson: [],
    chapter: [],
    course: [],
    project: [],
    group: [],
    institution: [],
    partner: [],
    user: [],
    quiz: [],
  };

  return results.reduce((acc, item) => {
    if (typesFitler && !typesFitler.includes(item.entity_type)) {
      return acc;
    }

    try {
      const transformedValue = transformFn(item);
      acc[item.entity_type].push(transformedValue);
    } catch {
      // Ignore, the value will not be added to the map
    }
    return acc;
  }, emptyMap);
}

/**
 * If the term is not an ID, it must have at least 3 characters after trimming.
 */
export function isSearchEnabled(term: string) {
  return TermOrIdSchema.safeParse(term).success;
}

/**
 * Returns a score for how well the query matches the item.
 * This is a javascript implementation of the SQL search query
 * in src/server/services/searchService.ts
 * @see {searchCourses} in src/server/services/searchService.ts
 * @param query
 * @param item
 */
export const getRelevancyScore = memoize(
  (
    query: string,
    item:
      | {
          id?: number;
          name?: string;
        }
      | string
  ) => {
    query = query.toLowerCase().trim();
    const id = typeof item === "object" ? item.id : undefined;
    const name = (typeof item === "string" ? item : item.name)?.toLowerCase();
    if (!id && !name) return 0;
    if (id === Number(query)) return 10;
    if (name === query) return 9;
    if (String(id)?.startsWith(query)) return 8;
    if (name?.startsWith(query)) return 7;
    if (name?.includes(query)) return 6;
    if (name) {
      const terms = Array.from(new Set(query.split(/\s+/).filter(Boolean)));
      const termScores = terms.map((term) => {
        const index = name.indexOf(term);
        return index !== -1 ? 1 - index / name.length : 0;
      });
      const averageTermScore =
        termScores.reduce((a, b) => a + b, 0) / termScores.length;
      return averageTermScore * 5;
    }
    return 0;
  },
  (query, item) => `${query.toLowerCase().trim()},${JSON.stringify(item)}`
);

export function getFilteredSearchWhereObj(
  tableName: string,
  filters: TableFilter<any>[],
  entityTypes: SearchEntityType[]
) {
  const where: Writeable<SearchWhere> = {
    entity_type: { in: entityTypes },
  };
  let allFiltersFullfilled = true;

  for (const filter of filters) {
    const key = `${tableName}.${filter.key}`;
    switch (filter.type) {
      case "number":
        where[key] = { [filter.subtype]: filter.value };
        break;

      case "boolean":
        where[key] = filter.value;
        break;

      case "date": {
        if (filter.subtype === "on") {
          where[key] = {
            between: [
              getUnixTime(startOfDay(filter.value)),
              getUnixTime(addDays(startOfDay(filter.value), 1)),
            ],
          };
        } else if (filter.subtype === "before") {
          where[key] = {
            lt: getUnixTime(startOfDay(filter.value)),
          };
        } else if (filter.subtype === "after") {
          where[key] = {
            gte: getUnixTime(addDays(startOfDay(filter.value), 1)),
          };
        } else if (filter.subtype === "between") {
          where[key] = {
            between: [
              getUnixTime(startOfDay(filter.value[0])),
              getUnixTime(addDays(startOfDay(filter.value[1]), 1)),
            ],
          };
        }
        break;
      }

      default:
        allFiltersFullfilled = false;
    }
  }

  return {
    where,
    allFiltersFullfilled,
  };
}

export function filterEntity(
  filters: TableFilter<any>[],
  entity: Record<string, any> | undefined
) {
  if (!entity) return false;
  try {
    for (const filter of filters) {
      switch (filter.type) {
        case "string": {
          const value = z.string().toLowerCase().parse(entity[filter.key]);
          const filterValue = filter.value.toLowerCase();

          if (
            (filter.subtype === "startsWith" &&
              !value.startsWith(filterValue)) ||
            (filter.subtype === "endsWith" && !value.endsWith(filterValue)) ||
            (filter.subtype === "eq" && value !== filterValue) ||
            (filter.subtype === "contains" && !value.includes(filterValue))
          ) {
            return false;
          }
          break;
        }

        case "finite": {
          const value = z.string().toLowerCase().parse(entity[filter.key]);
          const filterValues = filter.value.map((v) =>
            decodeURIComponent(v).toLowerCase()
          );
          if (!filterValues.includes(value)) {
            return false;
          }
          break;
        }
      }
    }

    return true;
  } catch {
    return false;
  }
}

export const ORAMA_SCHEMA = {
  entity_type: "enum",
  name: "string",
  content: "string",
  institution: {
    id: "number",
    licenses: "number",
    license_price: "number",
  },
  learningobject: {
    id: "number",
    is_christian: "boolean",
    grade_level: "number",
    num_lessons: "number",
    created: "number",
    updated: "number",
  },
  relationships: {
    author: "number[]",
    chapter: "number[]",
    course: "number[]",
    christian: "number[]",
    editor: "number[]",
    group: "number[]",
    institution: "number[]",
    lesson: "number[]",
    partner: "number[]",
    role: "number[]",
    region: "number[]",
  },
  tags: "string[]",
} as const;

export type OramaSchema = typeof ORAMA_SCHEMA;

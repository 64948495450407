import { Ref, SVGProps, forwardRef } from "react";

const SvgComponent = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="currentColor"
    ref={ref}
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.14175 0.980274C7.01906 1.12288 6.13563 1.38964 5.10534 1.89706C4.19748 2.34418 3.44861 2.88234 2.70064 3.62502C1.96041 4.36006 1.43423 5.09638 0.958803 6.0624C0.2937 7.41383 0.00830078 8.64253 0.00830078 10.1544C0.00830078 13.2561 1.55742 16.1312 4.14958 17.8404C6.37614 19.3087 9.18193 19.7341 11.7539 18.9934C12.7627 18.703 13.8178 18.1938 14.5739 17.6326L14.8502 17.4275L18.0121 20.5851C21.5899 24.1581 21.3337 23.9363 21.8824 23.9363C22.1275 23.9363 22.2075 23.9213 22.3661 23.8459C22.5954 23.737 22.7905 23.5473 22.9132 23.314C22.9916 23.1648 23.0042 23.0984 23.0048 22.8299C23.006 22.2609 23.241 22.5335 19.6481 18.9362C17.8056 17.0916 16.5 15.7588 16.522 15.7452C16.5428 15.7323 16.6969 15.5165 16.8645 15.2655C18.9443 12.15 18.9382 8.0671 16.8492 4.98191C15.4166 2.86612 13.1732 1.43953 10.6343 1.02982C10.0431 0.934431 8.71114 0.907949 8.14175 0.980274ZM10.2234 3.29796C11.6381 3.50813 12.9276 4.13593 13.9483 5.11152C15.0538 6.16805 15.7454 7.45313 16.0265 8.97297C16.1025 9.38368 16.1313 10.429 16.0794 10.8872C15.7213 14.0444 13.2988 16.5409 10.1603 16.9873C9.68368 17.0551 8.7054 17.054 8.23709 16.9851C7.29554 16.8466 6.46326 16.5472 5.64096 16.0512C5.17162 15.7681 4.84841 15.5174 4.42092 15.1049C3.79849 14.5043 3.37342 13.9241 3.01082 13.1805C1.93374 10.9715 2.10199 8.34403 3.45003 6.3221C4.61623 4.57295 6.46429 3.46408 8.57823 3.24503C8.97214 3.2042 9.76375 3.22968 10.2234 3.29796Z"
    />
  </svg>
);

const SearchIcon = forwardRef(SvgComponent);
SearchIcon.displayName = "SearchIcon";
export { SearchIcon };

import { Ref, SVGProps, forwardRef } from "react";

const SvgComponent = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="-1 -1 26 26"
    width={24}
    height={24}
    fill="none"
    stroke="currentColor"
    ref={ref}
    {...props}
  >
    <circle cx="12" cy="12" r="11" opacity="0.7" strokeWidth="2" />
    <path
      d="M12 1c6.08 0 11 4.92 11 11s-4.92 11-11 11S1 18.08 1 12"
      strokeWidth="4"
    />
  </svg>
);

const ProgressIcon = forwardRef(SvgComponent);
ProgressIcon.displayName = "ProgressIcon";
export { ProgressIcon };

import { Ref, SVGProps, forwardRef } from "react";

const SvgComponent = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 30 30"
    fill="none"
    ref={ref}
    {...props}
  >
    <path
      d="M1.397055,3.148616q30.052534,0,29.999996,0t-14.543755,15l14.543755,15h-30l.000004-30Z"
      transform="translate(-1.397051-3.148616)"
      strokeWidth="0.5"
      fill="currentColor"
    />
  </svg>
);

const FlagTailIcon = forwardRef(SvgComponent);
FlagTailIcon.displayName = "FlagTailIcon";
export { FlagTailIcon };

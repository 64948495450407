import { useAppStore } from "$hooks/useAppStore";
import { DEFAULT_EMAIL_SUBSCRIPTION_LEVEL_NAME } from "$lib/user/emailSubscriptions";
import { AppState } from "$store/index";
import { mdiChevronDown, mdiChevronUp } from "@mdi/js";
import { Icon } from "@mdi/react";
import { DropdownMenu as Dropdown } from "radix-ui";
import { KeyboardEventHandler, useRef, useState } from "react";
import { NavLink } from "../NavLink";

export type NavStudentNavigationProps = {
  isPreview: boolean;
};

export const NavStudentNavigation = ({
  isPreview,
}: NavStudentNavigationProps) => {
  if (isPreview) {
    return (
      <div className="flex w-[270px] shrink cursor-pointer items-center border-r border-anvil-100 px-2">
        <span className="whitespace-nowrap pr-2 text-xs italic">Welcome</span>
        <div className="flex flex-1 items-center justify-end overflow-hidden">
          <span className="truncate pr-1 tracking-tight">Jane Doe</span>
          <Icon className="h-5 w-5" path={mdiChevronDown} />
        </div>
      </div>
    );
  }

  return <NavStudentNavigationContent />;
};

export const NavStudentNavigationContent = () => {
  const contentRef = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(false);
  const currentUser = useAppStore((state) => state.currentUser);
  const items = getStudentNavItems(currentUser.preferences);
  const [focusedIndex, setFocusedIndex] = useState<number | null>(0);

  const handleKeyDown: KeyboardEventHandler = (e) => {
    if (e.key === "ArrowDown") {
      setFocusedIndex((prev) =>
        prev === null ? 0 : (prev + 1) % items.length
      );
      e.preventDefault();
    } else if (e.key === "ArrowUp") {
      const prev = focusedIndex;
      if (prev === null) {
        setOpen(false);
        setFocusedIndex(null);
      } else if (prev === 0) {
        setFocusedIndex(null);
      } else {
        setFocusedIndex((prev - 1 + items.length) % items.length);
      }
      e.preventDefault();
    } else if (e.key === "Enter" && focusedIndex !== null) {
      const link = contentRef.current?.querySelectorAll("a")[focusedIndex];
      link?.click();
      setOpen(false);
      setFocusedIndex(null);
    } else if (e.key === "Escape") {
      setOpen(false);
      setFocusedIndex(null);
    }
  };

  return (
    <Dropdown.Root open={open} modal={false}>
      <Dropdown.Trigger
        data-testid="nav-student-navigation-trigger"
        onClick={() => setOpen(!open)}
        onKeyDownCapture={(e) => {
          if ((e.key === "Enter" || e.key === "ArrowDown") && !open) {
            setOpen(true);
          }
        }}
        className="nav-control flex w-[270px] shrink cursor-pointer items-center border-r border-anvil-100 px-2"
      >
        <span className="whitespace-nowrap pr-2 text-xs italic">Welcome</span>
        <div className="flex flex-1 items-center justify-end overflow-hidden">
          <span className="truncate pr-1 tracking-tight">{`${currentUser.firstname} ${currentUser.lastname}`}</span>
          <Icon
            className="h-5 w-5"
            path={open ? mdiChevronUp : mdiChevronDown}
          />
        </div>
      </Dropdown.Trigger>
      <Dropdown.Content
        ref={contentRef}
        onInteractOutside={() => {
          setOpen(false);
          setFocusedIndex(null);
        }}
        data-testid="nav-student-navigation-dropdown"
        className="relative z-30 flex max-h-[var(--radix-dropdown-menu-content-available-height)] w-[var(--radix-dropdown-menu-trigger-width)] flex-col items-stretch bg-white py-2 shadow-md"
        onKeyDownCapture={handleKeyDown}
      >
        {items.map((item, index) => (
          <NavLink
            key={index}
            className={
              "py-1.5 pl-8 pr-8 text-left focus-within:bg-carbon-50 hover:bg-carbon-50" +
              (focusedIndex === index ? " bg-carbon-50" : "")
            }
            href={item.href}
            isSf3Href
            onMouseEnter={() => setFocusedIndex(index)}
          >
            {item.name}
          </NavLink>
        ))}
      </Dropdown.Content>
    </Dropdown.Root>
  );
};

const getStudentNavItems = (
  preferences: AppState["currentUser"]["preferences"]
) => {
  const emailFrequency =
    preferences.email_subscriptions.attempt_graded ??
    DEFAULT_EMAIL_SUBSCRIPTION_LEVEL_NAME;
  return [
    {
      name: "Account Settings",
      href: `/accountsettings/profile`,
    },
    {
      name: `Beta Features ${preferences.is_beta_user ? "On" : "Off"}`,
      href: `/accountsettings/profile`,
    },
    {
      name: "Change Password",
      href: `/accountsettings/password`,
    },
    {
      name:
        emailFrequency !== "never"
          ? `Receiving Emails ${emailFrequency
              .slice(0, 1)
              .toUpperCase()}${emailFrequency.slice(1)}${
              emailFrequency === "immediate" ? "ly" : ""
            }`
          : "Email Notifications Off",
      href: `/accountsettings/subscriptions`,
    },
    {
      name: "Logout",
      href: `/logout`,
    },
  ];
};

import Link from "next/link";
import { ComponentPropsWithRef, Ref, forwardRef } from "react";
import { tv } from "tailwind-variants";

type CommonProps = {
  size?: "sm" | "md" | "lg";
  variant?: "primary" | "neutral" | "outline" | "subtle" | "danger";
  startDecorator?: React.ReactNode;
  endDecorator?: React.ReactNode;
};

export type ButtonProps = CommonProps & ComponentPropsWithRef<"button">;

export type LinkButtonProps = CommonProps & {
  href: string;
} & ComponentPropsWithRef<"a">;

const styles = tv({
  base:
    "inline-flex items-center justify-center gap-2 text-nowrap rounded" +
    " focus:outline-1 focus:outline-offset-2 focus:outline-blue-700" +
    " disabled:pointer-events-none disabled:text-carbon-400",
  variants: {
    size: {
      sm: "h-6 px-3 text-xs",
      md: "h-[34px] px-4 text-sm",
      lg: "h-10 px-6 text-base",
    },
    variant: {
      primary:
        "border-transparent bg-carbon-800 !text-carbon-50 disabled:bg-carbon-200" +
        " hover:bg-carbon-700" +
        " focus-visible:bg-carbon-700" +
        " active:bg-carbon-900" +
        " data-[state=open]:bg-carbon-900",
      neutral:
        "bg-carbon-100 text-carbon-900 hover:bg-carbon-200" +
        " focus:bg-carbon-200 active:bg-carbon-300 disabled:bg-carbon-200",
      outline:
        "border border-carbon-800 bg-carbon-100 text-carbon-800" +
        " hover:border-carbon-800 hover:bg-carbon-200" +
        " focus-visible:border-carbon-800 focus-visible:bg-carbon-200" +
        " active:border-carbon-800 active:bg-carbon-100" +
        " data-[state=open]:border-carbon-800 data-[state=open]:bg-carbon-100" +
        " disabled:border-carbon-200 disabled:bg-transparent disabled:text-carbon-400",
      subtle:
        "border border-transparent text-carbon-500" +
        " hover:bg-carbon-200" +
        " focus-visible:bg-carbon-200" +
        " active:bg-transparent" +
        " data-[state=open]:bg-transparent" +
        " disabled:bg-transparent disabled:text-carbon-400",
      danger:
        "border border-transparent text-forge-500" +
        " hover:bg-red-50" +
        " focus-visible:bg-red-50" +
        " active:bg-transparent" +
        " data-[state=open]:bg-transparent" +
        " disabled:bg-transparent disabled:text-carbon-400",
    },
    hasStartDecorator: {
      true: "pl-4",
    },
    hasEndDecorator: { true: "" },
  },
  compoundVariants: [
    { size: "sm", hasStartDecorator: true, class: "pl-2" },
    { size: "md", hasStartDecorator: true, class: "pl-3" },
    { size: "lg", hasStartDecorator: true, class: "pl-4" },
    { size: "sm", hasEndDecorator: true, class: "pr-2" },
    { size: "md", hasEndDecorator: true, class: "pr-3" },
    { size: "lg", hasEndDecorator: true, class: "pr-4" },
  ],
});

export const Button = forwardRef(function Button(
  {
    size = "md",
    variant = "primary",
    startDecorator,
    endDecorator,
    children,
    className,
    ...buttonProps
  }: ButtonProps,
  ref: Ref<HTMLButtonElement>
) {
  return (
    <button
      ref={ref}
      className={styles({
        size,
        variant,
        hasStartDecorator: !!startDecorator,
        hasEndDecorator: !!endDecorator,
        className,
      })}
      {...buttonProps}
    >
      {startDecorator ? (
        <span className="[display:inherit]">{startDecorator}</span>
      ) : null}

      {children}

      {endDecorator ? (
        <span className="[display:inherit]">{endDecorator}</span>
      ) : null}
    </button>
  );
});

export const LinkButton = forwardRef(function LinkButton(
  {
    size = "md",
    variant = "primary",
    startDecorator,
    endDecorator,
    children,
    className,
    href,
    ...anchorProps
  }: LinkButtonProps,
  ref: Ref<HTMLAnchorElement>
) {
  return (
    <Link
      ref={ref}
      className={styles({
        size,
        variant,
        hasStartDecorator: !!startDecorator,
        hasEndDecorator: !!endDecorator,
        className,
      })}
      href={href}
      {...anchorProps}
    >
      {startDecorator ? (
        <span className="[display:inherit]">{startDecorator}</span>
      ) : null}

      {children}

      {endDecorator ? (
        <span className="[display:inherit]">{endDecorator}</span>
      ) : null}
    </Link>
  );
});

import cn from "classnames";
import { Tooltip as RadixTooltip } from "radix-ui";
import React from "react";

export type TooltipProps = {
  children: React.ReactNode;
  /**
   * Tooltip title
   */
  title: string | React.ReactNode;
  /**
   * Animate the tooltip close, with a fade out animation
   */
  animateClose?: boolean;
  /**
   * Tooltip size
   */
  size?: "sm" | "md";
  /**
   * Hide the arrow
   */
  hideArrow?: boolean;
  triggerProps?: RadixTooltip.TooltipTriggerProps;
  portalProps?: RadixTooltip.TooltipPortalProps;
  contentProps?: RadixTooltip.TooltipContentProps;
  arrowProps?: RadixTooltip.TooltipArrowProps;
} & RadixTooltip.TooltipProps;

const Tooltip = (props: TooltipProps) => {
  const {
    children,
    title,
    animateClose = false,
    size = "md",
    hideArrow = false,
    triggerProps,
    portalProps,
    contentProps = {},
    arrowProps = {},
    ...tooltipProps
  } = props;

  const { className: contentClassName, ...restContentProps } = contentProps;
  const { className: arrowClassName, ...restArrowProps } = arrowProps;
  const arrowSize = getArrowSize(size);

  if (!title) {
    return children;
  }

  return (
    <RadixTooltip.Root {...tooltipProps} disableHoverableContent>
      <RadixTooltip.Trigger asChild {...triggerProps}>
        {children}
      </RadixTooltip.Trigger>
      <RadixTooltip.Portal {...portalProps}>
        <RadixTooltip.Content
          className={cn(
            "TooltipContent z-50 max-w-xs rounded-lg bg-oldsfgray-600 text-sm text-white",
            {
              "TooltipContent-animate-close": animateClose,
              "p-4 text-sm": size === "md",
              "p-2.5 text-xs": size === "sm",
            },
            contentClassName
          )}
          sideOffset={hideArrow ? 0 : arrowSize.offset}
          side="bottom"
          hideWhenDetached
          {...restContentProps}
        >
          {title}
          {!hideArrow && (
            <RadixTooltip.Arrow
              className="fill-oldsfgray-600"
              height={arrowSize.height}
              width={arrowSize.width}
              {...restArrowProps}
            />
          )}
        </RadixTooltip.Content>
      </RadixTooltip.Portal>
    </RadixTooltip.Root>
  );
};

function getArrowSize(size: TooltipProps["size"]) {
  if (size === "sm") {
    return {
      height: 6,
      width: 12,
      offset: 3,
    };
  }

  return {
    height: 11,
    width: 22,
    offset: 5,
  };
}

export { Tooltip };

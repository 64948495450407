/**
 * Check if the current window is being rendered inside an iframe
 */
export function isIframe() {
  if (typeof window === "undefined") {
    return false;
  }

  return window.self !== window.top;
}

import { api } from "$lib/api";
import { useEffect } from "react";

export function useFetchFirstGlobalSearchPageAfterIds({
  data,
  fetchNextPage,
}: Pick<
  ReturnType<typeof api.search.globalSearch.useInfiniteQuery>,
  "data" | "fetchNextPage"
>) {
  useEffect(() => {
    if (data && data.pages.length === 1 && data.pages[0].nextCursor === 0) {
      fetchNextPage();
    }
  }, [data, fetchNextPage]);
}

import { bytesToFileSize } from "$lib/file/fileSize";

export const ERROR_MESSAGES = {
  required: "This field is required",
  number: {
    int: "Must be a whole number",
    positive: "Must be a positive number",
    nonnegative: "Must be a non-negative number",
  },
  exceededCharactersLimit: (limit: number, name?: string) =>
    `${
      name ? `${name} must` : "Must"
    } not be greater than ${limit} characters long`,
  file: {
    invalidType: "Invalid file type.",
    exceedsMaxSize: (maxBytes: number) =>
      `File size exceeds ${bytesToFileSize(maxBytes, 2)}`,
    serializedFile:
      "Oops! You've navigated away from the page, and we've lost access to this file. Please browse for it again before submitting.",
    fileNotUploaded: "Cannot save a file that was not uploaded.",
  },
};

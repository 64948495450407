import { useDebounce } from "$hooks/useDebounce";
import { api } from "$lib/api";
import { COURSE_TYPE } from "$lib/course/course";
import { NavbarPermissionsResult } from "$server/api/routers/navbarRouter";
import {
  Assessment,
  Chapter,
  Course,
  Lesson,
  Project,
} from "@studyforge/prisma";
import { SkipToken } from "@tanstack/react-query";
import { useEffect, useMemo, useState } from "react";
import { firstBy } from "thenby";
import { CourseNavigationData } from "./index";
import {
  type Initial,
  type Level,
  MapHelper,
  type SelectedLevels,
  buildCourseStreamsList,
  buildCoursesList,
  bySortOrder,
  checkIsAssessment,
  getInitialChapterLevels,
  getInitialCourseLevels,
  getInitialCourseStreamsLevels,
  getInitialLessonLevels,
  getInitialPrivateOrSampleCourseLevels,
} from "./lib";
import { CourseNavigationArgs } from "./useCourseNavigation";

// TODO: The "course.find" TRPC does not return a good typed result when using the "include" argument.
// Do this for now to get proper typing.
export type CourseFindOutput = Course & {
  assessments?: Assessment[] | undefined;
  projects?: Project[];
  chapters?: Array<
    Chapter & {
      lessons: Lesson[];
      assessments: Assessment[] | undefined;
      projects: Project[];
    }
  >;
};

// 2 minuts of stale time for the navigation requests
export const STALE_TIME = 2 * 60 * 1000;

/**
 *  Use the same trpc on all levels to enable react-query caching to prevent multiple requests
 */
function useCourses({
  regionId,
  onlyCoursesUserCanEdit,
  enabled,
}: {
  regionId: number | undefined;
  onlyCoursesUserCanEdit: boolean;
  enabled: boolean;
}) {
  return api.course.findAll.useQuery(
    {
      // selected region is sure to exists because of the enabled condition
      regionId: regionId!,
      onlyCoursesUserCanEdit,
    },
    { enabled: enabled && !!regionId, staleTime: STALE_TIME }
  );
}

/**
 * Gets the same arguments for any "course.find" TRPC query throughout useCourseNavigation
 * This ensures the requests will be cached accordingly
 */
export const getFindCourseArguments = (
  courseId: number,
  opts: { excludeQuizzes?: boolean; checkCanEdit?: boolean } = {}
): Exclude<Parameters<typeof api.course.find.useQuery>[0], SkipToken> => ({
  courseId,
  include: {
    chapters: {
      include: {
        lessons: true,
        assessments: !opts.excludeQuizzes,
        projects: true,
      },
    },
    projects: true,
    assessments: !opts.excludeQuizzes,
  },
  checkCanEdit: opts.checkCanEdit,
});

/**
 *  Use the same trpc on all levels to enable react-query caching to prevent multiple requests
 */
export function useCourse({
  courseId,
  enabled = true,
  excludeQuizzes,
}: {
  courseId: number | undefined;
  enabled?: boolean;
  excludeQuizzes?: boolean;
}) {
  return api.course.find.useQuery(
    getFindCourseArguments(courseId!, { excludeQuizzes }),
    {
      enabled: enabled && !!courseId,
      staleTime: STALE_TIME,
    }
  );
}

export function useRegionsData(level: Level): {
  data: CourseNavigationData | null;
  rawData: unknown;
} {
  const { data: regions } = api.region.all.useQuery(undefined, {
    enabled: level === "regions",
    staleTime: STALE_TIME,
  });

  return useMemo(() => {
    if (!regions) return EMPTY_DATA;
    return {
      data: [
        {
          type: "region",
          title: "Regions",
          list: regions.sort(firstBy("name").thenBy("id")).map((region) => ({
            type: "region",
            id: region.id,
            name: region.name,
            displayName: region.name,
          })),
        },
      ],
      rawData: regions,
    };
  }, [regions]);
}

export function useCourseStreamsData(
  level: Level,
  selectedLevels: SelectedLevels,
  onlyCoursesUserCanEdit: boolean
): {
  data: CourseNavigationData | null;
  rawData: unknown;
} {
  const { data: coursesData } = useCourses({
    regionId: selectedLevels["regions"]?.id,
    onlyCoursesUserCanEdit,
    enabled: level === "course_streams" && selectedLevels["regions"] !== null,
  });

  return useMemo(() => {
    if (!coursesData) return EMPTY_DATA;

    const hasSampleCourses =
      coursesData.courses.filter((c) => c.course_type === COURSE_TYPE.SAMPLE)
        .length > 0;
    const hasPrivateCourses =
      coursesData.courses.filter((c) => c.course_type === COURSE_TYPE.PRIVATE)
        .length > 0;
    return {
      data: buildCourseStreamsList(
        coursesData.courses,
        {
          hasSampleCourses,
          hasPrivateCourses,
        },
        selectedLevels?.regions?.id ?? null
      ),
      rawData: coursesData,
    };
  }, [coursesData, selectedLevels.regions?.id]);
}

const EMPTY_DATA = { data: null, rawData: null };

export function useSampleCoursesData(
  level: Level,
  selectedLevels: SelectedLevels,
  onlyCoursesUserCanEdit: boolean
): { data: CourseNavigationData | null; rawData: unknown } {
  const { data } = useCourses({
    regionId: selectedLevels["regions"]?.id,
    onlyCoursesUserCanEdit,
    enabled: level === "sample_courses",
  });
  const { permissions: globalPermissions } = useGlobalNavPermissions();

  return useMemo(() => {
    if (!data) {
      return EMPTY_DATA;
    }

    const sampleCourses = data.courses
      .filter((c) => c.course_type === COURSE_TYPE.SAMPLE)
      .sort(firstBy("name"));

    return {
      data: [
        {
          type: "sample_course",
          title: "Sample Courses",
          list: sampleCourses.map((course) =>
            MapHelper.courseToListItem(
              course,
              false, // Sample courses are not editable
              globalPermissions?.includes("viewLTILinks")
            )
          ),
        },
      ],
      rawData: data,
    };
  }, [data, globalPermissions]);
}

export function usePrivateCoursesData(
  level: Level,
  selectedLevels: SelectedLevels,
  onlyCoursesUserCanEdit: boolean
): { data: CourseNavigationData | null; rawData: unknown } {
  const { data } = useCourses({
    regionId: selectedLevels["regions"]?.id,
    onlyCoursesUserCanEdit,
    enabled: level === "private_courses",
  });
  const { permissions: globalPermissions } = useGlobalNavPermissions();

  return useMemo(() => {
    if (!data) {
      return { data: null, rawData: null };
    }
    const privateCourses = data.courses
      .filter((c) => c.course_type === COURSE_TYPE.PRIVATE)
      .sort(firstBy("name"));

    return {
      data: [
        {
          type: "private_course",
          title: "Private Courses",
          list: privateCourses.map((course) =>
            MapHelper.courseToListItem(
              course,
              data.editableCourseIds.includes(course.id),
              globalPermissions?.includes("viewLTILinks")
            )
          ),
        },
      ],
      rawData: data,
    };
  }, [data, globalPermissions]);
}

export function useCoursesData(
  level: Level,
  selectedLevels: SelectedLevels,
  onlyCoursesUserCanEdit: boolean
): { data: CourseNavigationData | null; rawData: unknown } {
  const { data } = useCourses({
    regionId: selectedLevels["regions"]?.id!,
    onlyCoursesUserCanEdit,
    enabled: level === "courses" && selectedLevels["regions"] !== null,
  });
  const { permissions: globalPermissions } = useGlobalNavPermissions();

  return useMemo(() => {
    const courseStreamId = selectedLevels["course_streams"]?.id;
    if (!data || typeof courseStreamId !== "number") {
      return EMPTY_DATA;
    }
    return {
      data: buildCoursesList(
        data,
        courseStreamId,
        globalPermissions?.includes("viewLTILinks")
      ),
      rawData: {
        ...data,
        streamCourse: data.courses.find((c) => c.id === courseStreamId),
      },
    };
  }, [data, globalPermissions, selectedLevels]);
}

export function useChaptersData(
  level: Level,
  selectedLevels: SelectedLevels,
  courseEditable: boolean = false
): { data: CourseNavigationData | null; rawData: unknown } {
  const { permissions: globalPermissions } = useGlobalNavPermissions();
  const course = getCourseFromSelectedLevels(selectedLevels);
  const { data } = useCourse({
    courseId: course?.id,
    excludeQuizzes: !globalPermissions?.includes("quizNavigate"),
    enabled:
      level === "chapters" &&
      course !== null &&
      Array.isArray(globalPermissions),
  });
  const canViewLTILinks = globalPermissions?.includes("viewLTILinks");

  return useMemo(() => {
    if (!data) return { data: null, rawData: null };

    const courseData = data as unknown as CourseFindOutput;
    return {
      data: [
        {
          type: "project",
          title: "Projects",
          list:
            courseData.projects?.map((p) =>
              MapHelper.projectToListItem(p, canViewLTILinks)
            ) ?? [],
        },
        {
          type: "chapter",
          title: courseData.chapter_label,
          list: [
            ...(courseData.chapters ?? []),
            ...(courseData.assessments ?? []),
          ]
            .sort(bySortOrder)
            .map((item) => {
              const isAssessment = checkIsAssessment(item);
              if (isAssessment) {
                return MapHelper.assessmentToListItem(
                  item,
                  selectedLevels,
                  courseEditable,
                  canViewLTILinks
                );
              }
              return {
                type: "chapter",
                id: item.id,
                courseId: data.id,
                name: item.name,
                displayName: `${courseData.chapter_label} ${item.number} - ${item.name}`,
                canEdit: courseEditable,
                canViewLTILinks: canViewLTILinks || false,
              };
            }),
        },
      ],
      rawData: data,
    };
  }, [canViewLTILinks, courseEditable, data, selectedLevels]);
}

export function useLessonsData(
  level: Level,
  selectedLevels: SelectedLevels,
  courseEditable: boolean = false
): { data: CourseNavigationData | null; rawData: unknown } {
  const course = getCourseFromSelectedLevels(selectedLevels);
  const { permissions: globalPermissions } = useGlobalNavPermissions();
  const { data } = useCourse({
    courseId: course?.id,
    excludeQuizzes: !globalPermissions?.includes("quizNavigate"),
    enabled:
      level === "lessons" &&
      course !== null &&
      Array.isArray(globalPermissions),
  });
  const canViewLTILinks = globalPermissions?.includes("viewLTILinks");

  return useMemo(() => {
    const chapterId = selectedLevels["chapters"]?.id;
    if (typeof chapterId !== "number" || !data) {
      return { data: null, rawData: null };
    }

    const courseData = data as unknown as CourseFindOutput;
    const chapter = courseData.chapters?.find((ch) => ch.id === chapterId);
    if (!chapter) {
      return { data: null, rawData: null };
    }

    return {
      data: [
        {
          type: "project",
          title: "Projects",
          list: chapter.projects.map((p) =>
            MapHelper.projectToListItem(p, canViewLTILinks)
          ),
        },
        {
          type: "lesson",
          title: "Lessons",
          list: [...chapter.lessons, ...(chapter.assessments ?? [])]
            .sort(bySortOrder)
            .map((item) => {
              const isAssessment = checkIsAssessment(item);
              if (isAssessment) {
                return MapHelper.assessmentToListItem(
                  item,
                  selectedLevels,
                  courseEditable,
                  canViewLTILinks
                );
              }

              return {
                type: "lesson",
                id: item.id,
                name: item.name,
                displayName: `Lesson ${item.number} - ${item.name}`,
                canEdit: courseEditable,
                canViewLTILinks: canViewLTILinks || false,
              };
            }),
        },
      ],
      rawData: chapter,
    };
  }, [courseEditable, data, canViewLTILinks, selectedLevels]);
}

export function useSearchData(
  selectedLevels: SelectedLevels,
  searchTerm: string | undefined,
  onlyCoursesUserCanEdit: boolean,
  searchInclude: CourseNavigationArgs["searchInclude"]
): { data: CourseNavigationData | null; rawData: unknown } {
  const debouncedSearchTerm = useDebounce(searchTerm ?? "", 500);
  const { data } = api.search.searchCourses.useQuery(
    {
      query: debouncedSearchTerm!,
      // regionId is always defined because of the enabled flag
      within: { region: selectedLevels.regions?.id! },
      include: searchInclude,
      onlyCoursesUserCanEdit,
    },
    {
      enabled: debouncedSearchTerm.length > 0 && !!selectedLevels.regions?.id,
      staleTime: STALE_TIME,
    }
  );

  return useMemo(() => {
    if (!data) return { data: null, rawData: null };

    const quizzes = data.filter((result) => result.type === "Assessment");
    const chapters = data.filter((result) => result.type === "Chapter");
    const courses = data.filter((result) => result.type === "Course");
    return {
      data: [
        {
          type: "quiz",
          title: "Quizzes",
          list: quizzes.map(MapHelper.searchResultsToListItem),
        },
        {
          type: "chapter",
          title: "Chapters",
          list: chapters.map(MapHelper.searchResultsToListItem),
        },
        {
          type: "course",
          title: "Courses",
          list: courses.map(MapHelper.searchResultsToListItem),
        },
      ],
      rawData: data,
    };
  }, [data]);
}

/**
 * The course navigation can start from the "chapters" level, if the courseId is provided.
 * This hook loads the "selectedLevels" so that the course navigation works as it should
 */
export function useInitialSetup(
  initial: Initial | undefined,
  setSelectedLevels: (selectedLevels: SelectedLevels) => void,
  onlyCoursesUserCanEdit: boolean
) {
  const [loaded, setLoaded] = useState(false);
  const trpc = api.useUtils();

  // If these dependencies change, we need to load the data again
  useEffect(() => {
    setLoaded(false);
  }, [initial, onlyCoursesUserCanEdit]);

  async function loadData() {
    if (!initial) return;
    let levels: SelectedLevels | undefined = undefined;

    switch (initial.level) {
      case "course_streams":
        levels = await getInitialCourseStreamsLevels(trpc, initial.regionId);
        break;
      case "private_courses":
      case "sample_courses":
        levels = await getInitialPrivateOrSampleCourseLevels(
          trpc,
          initial.regionId,
          initial.level === "private_courses"
            ? COURSE_TYPE.PRIVATE
            : COURSE_TYPE.SAMPLE
        );
        break;
      case "courses":
        levels = await getInitialCourseLevels(trpc, initial.courseId);
        break;
      case "chapters":
        levels = await getInitialChapterLevels(
          trpc,
          initial.courseId,
          onlyCoursesUserCanEdit
        );
        break;
      case "lessons":
        levels = await getInitialLessonLevels(
          trpc,
          initial.chapterId,
          onlyCoursesUserCanEdit
        );
        break;
    }

    if (levels) {
      setSelectedLevels(levels);
    }
  }

  if (!loaded) {
    setLoaded(true);
    void loadData();
  }
}

export function useGlobalNavPermissions(): Partial<NavbarPermissionsResult> {
  const { data } = api.navbar.loadPermissions.useQuery(undefined, {
    staleTime: Infinity,
  });

  return useMemo(() => {
    if (!data) return {};
    return data;
  }, [data]);
}

export function useLoginVia(initial: string = "manual") {
  const { data, isPending } = api.navbar.loadPermissions.useQuery(undefined, {
    staleTime: Infinity,
  });
  if (isPending) return initial;
  return data?.loginVia ?? initial;
}

function getCourseFromSelectedLevels(selectedLevels: SelectedLevels) {
  return (
    selectedLevels["courses"] ??
    selectedLevels["private_courses"] ??
    selectedLevels["sample_courses"]
  );
}

import { Ref, SVGProps, forwardRef } from "react";

const SvgComponent = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="8"
    height="13"
    viewBox="0 0 8 13"
    fill="none"
    ref={ref}
    {...props}
  >
    <path
      d="M6 5L0.993059 9.875L1.153e-08 8.90811L4.03426 5L0.0203751 1.09189L1.01343 0.125L6 5Z"
      fill="currentColor"
    />
  </svg>
);

const GreaterThanIcon = forwardRef(SvgComponent);
GreaterThanIcon.displayName = "GreaterThanIcon";
export { GreaterThanIcon };


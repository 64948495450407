/**
 * This is the client-side entrypoint for your tRPC API.
 * It's used to create the `api` object which contains the Next.js App-wrapper
 * as well as your typesafe react-query hooks.
 *
 * We also create a few inference helpers for input and output types
 */
import { env } from "$env";
import { type AppRouter } from "$server/api/root";
import {
  CreateTRPCClientOptions,
  httpBatchLink,
  httpLink,
  loggerLink,
  splitLink,
} from "@trpc/client";
import { createTRPCNext } from "@trpc/next";
import { createTRPCReact } from "@trpc/react-query";
import { type inferRouterInputs, type inferRouterOutputs } from "@trpc/server";
import superjson from "superjson";
import { queryClient } from "./queryClient";

const getBaseUrl = () => {
  const appURL = new URL(env.NEXT_PUBLIC_APP_URL || "http://localhost:3000");

  // browser should use relative url
  if (typeof window !== "undefined") {
    return appURL.pathname;
  }

  // SSR should use absolute url
  return appURL.href;
};

const url = `${getBaseUrl()}/api/trpc`;

export const trpcConfig: CreateTRPCClientOptions<AppRouter> = {
  /**
   * Links used to determine request flow from client to server
   * @see https://trpc.io/docs/links
   * */
  links: [
    loggerLink({
      enabled: (opts) =>
        env.NEXT_PUBLIC_NODE_ENV === "development" ||
        (opts.direction === "down" && opts.result instanceof Error),
    }),
    splitLink({
      condition: (op) => {
        return op.path === "course.findAll";
      },
      true: httpLink({ url, transformer: superjson }),
      false: httpBatchLink({ url, transformer: superjson }),
    }),
  ],
};

export const clientApi = createTRPCReact<AppRouter>();

/**
 * A set of typesafe react-query hooks for your tRPC API
 */
export const api = createTRPCNext<AppRouter>({
  config() {
    return { ...trpcConfig, queryClient };
  },
  /**
   * Whether tRPC should await queries when server rendering pages
   * @see https://trpc.io/docs/nextjs#ssr-boolean-default-false
   */
  ssr: false,
  transformer: superjson,
});

/**
 * Inference helper for inputs
 * @example type HelloInput = RouterInputs['example']['hello']
 **/
export type RouterInputs = inferRouterInputs<AppRouter>;
/**
 * Inference helper for outputs
 * @example type HelloOutput = RouterOutputs['example']['hello']
 **/
export type RouterOutputs = inferRouterOutputs<AppRouter>;

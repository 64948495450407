import { z } from "zod";
import type { create, insert, search } from "@orama/orama";
export * from "./OramaSchema";
import type { OramaSchema } from "./OramaSchema";

export const SEARCH_ENTITY_TYPES = [
  "chapter",
  "course",
  "group",
  "institution",
  "interactive",
  "lesson",
  "partner",
  "project",
  "question",
  "quiz",
  "reading",
  "user",
  "video",
] as const;
export const SEARCH_RELATIONSHIP_TYPES = [
  "author",
  "chapter",
  "christian",
  "course",
  "editor",
  "group",
  "institution",
  "lesson",
  "partner",
  "role",
  "region",
] as const;
export type OramaDb = ReturnType<typeof create<OramaSchema>>;

export type SearchEntityType = (typeof SEARCH_ENTITY_TYPES)[number];
export type SearchRelationshipType = (typeof SEARCH_RELATIONSHIP_TYPES)[number];
export type SearchRelationships = Partial<
  Record<SearchRelationshipType, number[]>
>;
export type SearchInsert = Parameters<typeof insert<OramaDb>>[1];
export type SearchEntity = {
  id: `${SearchEntityType}-${string}`;
  entity_type: SearchEntityType;
  entity_id: string;
  name?: string;
  content?: string;
  relationships: SearchRelationships;
  institution?: Partial<{
    id: number;
    name: string;
    licenses: number;
    license_price: number;
    partner: string;
    contact_name: string;
    contact_email: string;
    lms: string;
    consumer_key: string;
  }>;
  learningobject?: {
    type: "video" | "question" | "interactive" | "reading";
    id: number;
    created: number;
    updated: number;
    name?: string;
    authors?: string;
    theme?: string;
    is_christian?: boolean;
    grade_level?: number;
    question_type?: string;
    created_by?: string;
    updated_by?: string;
    num_lessons?: number;
  };
  tags?: string[];
};

export const NumberTermSchema = z.coerce.number().int().gte(1);

export const TermOrIdSchema = z
  .string()
  .trim()
  .pipe(z.union([z.literal(""), NumberTermSchema, z.string().min(3)]))
  .transform((value) => {
    if (value === "") return undefined;
    return String(value);
  });

export const DefaultSearchInputSchema = z
  .object({
    term: TermOrIdSchema.optional(),
    limit: z.number().default(10),
    offset: z.number().default(0),
    tolerance: z.number().default(0),
    where: z.record(z.string(), z.any()).optional(),
  })
  .transform((values) => {
    if (!!values.term) {
      return {
        ...values,
        properties: ["name" as const, "content" as const, "tags" as const],
        boost: { name: 3, tags: 0.8 },
      };
    } else {
      // For what looks a bug with Orama, searching with an empty `term` with the `properties`
      // values set always return nothing. So we need to remove it if we don't have a `term`.
      return values;
    }
  });
export const GetByIdInputSchema = z.object({
  entity_id: z.string().min(1),
  entity_types: z.array(z.enum(SEARCH_ENTITY_TYPES)),
});

export type SearchWhere = Parameters<
  typeof search<ReturnType<typeof create<OramaSchema>>>
>[1]["where"] &
  Record<string, any>;
export type SearchRoutes = {
  DEFAULT: {
    input: Omit<z.input<typeof DefaultSearchInputSchema>, "where"> & {
      where?: Parameters<
        typeof search<ReturnType<typeof create<OramaSchema>>>
      >[1]["where"];
    };
    output: {
      count: number;
      elapsed: { raw: number; formatted: string };
      hits: {
        id: string;
        score: number;
        document: SearchEntity;
      }[];
    };
  };
  getById: {
    input: z.input<typeof GetByIdInputSchema>;
    output: SearchEntity[];
  };
};

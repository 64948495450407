export async function copyToClipboard(text: string) {
  // use the clipboard API when available
  if (navigator.clipboard) {
    return navigator.clipboard.writeText(text);
  } else {
    // fallback for older browsers
    return new Promise<void>((resolve, reject) => {
      const textarea = document.createElement("textarea");
      textarea.value = text;
      document.body.appendChild(textarea);
      // save the current focus
      const currentFocus = document.activeElement as HTMLElement | null;
      textarea.select();
      try {
        const successful = document.execCommand("copy");
        if (!successful) {
          throw new Error("Failed to copy text to clipboard");
        }
      } catch (err) {
        reject(err);
      } finally {
        document.body.removeChild(textarea);
        // restore the focus
        currentFocus?.focus();
      }
      resolve();
    });
  }
}

import { z } from "zod";
import { ERROR_MESSAGES } from "./form/errorMessages";

export const FeatureFlag = {
  SF4_HOME: "sf4_home",
  TEACHER_QUIZ_QUESTIONS: "teacher_quiz_questions",
  LESSON_BUILDER: "lesson_builder",
} as const;

export type FeatureFlag = (typeof FeatureFlag)[keyof typeof FeatureFlag];

export function isFeatureFlag(name: string): name is FeatureFlag {
  return Object.values(FeatureFlag).includes(name);
}

export const FeatureFlagFormInputSchema = z.object({
  name: z.string().min(1, ERROR_MESSAGES.required).max(255),
  display_name: z.string(),
  description: z.string(),
  allow_opt_in: z.boolean(),
  enabled_for_all: z.boolean().catch(false),
  enabled_for_roles: z.array(z.string()).catch([]),
  enabled_for_users: z
    .array(
      z.object({
        id: z.number(),
        name: z.string(),
      })
    )
    .catch([]),
  enabled_for_contexts: z
    .array(
      z.object({
        context_id: z.number().int(),
        entity_id: z.number().int().nullable(),
        entity_name: z.string().nullable(),
        entity_type: z.string(),
      })
    )
    .catch([]),
});
export const FeatureFlagFormTransformSchema =
  FeatureFlagFormInputSchema.transform((val) => {
    return {
      name: val.name,
      display_name: val.display_name,
      description: val.description,
      allow_opt_in: val.allow_opt_in,
      conditions: {
        enabled_for_all: val.enabled_for_all,
        roles: Array.from(new Set(val.enabled_for_roles)),
        user_ids: Array.from(new Set(val.enabled_for_users.map((u) => u.id))),
        context_ids: Array.from(
          new Set(val.enabled_for_contexts.map((c) => c.context_id))
        ),
      },
    };
  });

export const ConditionsSchema = z
  .object({
    enabled_for_all: z.boolean().catch(false).catch(false),
    roles: z.array(z.string()).catch([]).catch([]),
    user_ids: z.array(z.number()).catch([]).catch([]),
    context_ids: z.array(z.number()).catch([]).catch([]),
  })
  .transform((conditions) => ({
    ...conditions,
    roles: Array.from(new Set(conditions.roles)),
    user_ids: Array.from(new Set(conditions.user_ids)),
    context_ids: Array.from(new Set(conditions.context_ids)),
  }));

export type FeatureFlagConditions = z.infer<typeof ConditionsSchema>;

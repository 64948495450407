import { HTMLAttributes } from "react";

export function TextWithHighlight({
  text,
  termToHighlight,
  bold = true,
  italic = true,
  Component = "p",
  ...restProps
}: {
  text: string;
  termToHighlight: string | undefined;
  bold?: boolean;
  italic?: boolean;
  Component?: string;
} & HTMLAttributes<HTMLParagraphElement>) {
  const parts = text.split(new RegExp(`(${termToHighlight})`, "gi"));

  if (!termToHighlight) return <Component {...restProps}>{text}</Component>;
  return (
    <Component {...restProps}>
      {parts.map((part, i) =>
        part.toLowerCase() === termToHighlight.toLowerCase() ? (
          <span
            key={i}
            className={`${bold ? "font-bold" : ""} ${italic ? "italic" : ""}`}
          >
            {part}
          </span>
        ) : (
          part
        )
      )}
    </Component>
  );
}

import { Ref, SVGProps, forwardRef } from "react";

const SvgComponent = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 32 32"
    fill="currentColor"
    ref={ref}
    {...props}
  >
    <path d="M11 13H5C4.73478 13 4.48043 12.8946 4.29289 12.7071C4.10536 12.5196 4 12.2652 4 12V6C4 5.73478 4.10536 5.48043 4.29289 5.29289C4.48043 5.10536 4.73478 5 5 5C5.26522 5 5.51957 5.10536 5.70711 5.29289C5.89464 5.48043 6 5.73478 6 6V8.98125C7.93375 6.8 11.3263 4 16 4C21.3525 4 24.5725 7.15875 24.7075 7.2925C24.8955 7.47981 25.0013 7.73412 25.0018 7.99948C25.0023 8.26485 24.8973 8.51953 24.71 8.7075C24.5227 8.89547 24.2684 9.00134 24.003 9.00181C23.7377 9.00227 23.483 8.89731 23.295 8.71C23.2612 8.67625 20.49 6 16 6C11.625 6 8.47125 9.08125 6.93 11H11C11.2652 11 11.5196 11.1054 11.7071 11.2929C11.8946 11.4804 12 11.7348 12 12C12 12.2652 11.8946 12.5196 11.7071 12.7071C11.5196 12.8946 11.2652 13 11 13ZM27 19H21C20.7348 19 20.4804 19.1054 20.2929 19.2929C20.1054 19.4804 20 19.7348 20 20C20 20.2652 20.1054 20.5196 20.2929 20.7071C20.4804 20.8946 20.7348 21 21 21H25.07C23.5287 22.9188 20.375 26 16 26C11.51 26 8.73875 23.3238 8.705 23.29C8.51703 23.1027 8.26235 22.9977 7.99698 22.9982C7.73162 22.9987 7.47731 23.1045 7.29 23.2925C7.10269 23.4805 6.99773 23.7352 6.99819 24.0005C6.99866 24.2659 7.10453 24.5202 7.2925 24.7075C7.4275 24.8413 10.6475 28 16 28C20.6737 28 24.0662 25.2 26 23.0187V26C26 26.2652 26.1054 26.5196 26.2929 26.7071C26.4804 26.8946 26.7348 27 27 27C27.2652 27 27.5196 26.8946 27.7071 26.7071C27.8946 26.5196 28 26.2652 28 26V20C28 19.7348 27.8946 19.4804 27.7071 19.2929C27.5196 19.1054 27.2652 19 27 19Z" />
  </svg>
);

const SwitchIcon = forwardRef(SvgComponent);
SwitchIcon.displayName = "SwitchIcon";
export { SwitchIcon };

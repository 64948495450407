import { portalContainer } from "$components/features/nav/NavBar/portalContainer";
import { CheckIcon } from "$components/shared/Icons";
import { EllipsisIcon } from "$components/shared/Icons/EllipsisIcon";
import { ProgressIcon } from "$components/shared/Icons/ProgressIcon";
import { Tooltip } from "$components/shared/Tooltip";
import { env } from "$env";
import { useAppStore } from "$hooks/useAppStore";
import { CourseNavigationItem } from "$hooks/useCourseNavigation";
import { Level } from "$hooks/useCourseNavigation/lib";
import { generateLTILink } from "$lib/navigation/generateLTILink";
import { getRootSF3URL } from "$lib/navigation/routing";
import { copyToClipboard } from "$lib/utility/copyToClipboard";
import { Course } from "@studyforge/prisma";
import { DropdownMenu as Dropdown } from "radix-ui";
import { PropsWithChildren, forwardRef, useState } from "react";
import { tv } from "tailwind-variants";
import { NavCourseVersionSwitch } from "./NavCourseVersionSwitch";

const navItemActionsVariants = tv({
  slots: {
    container:
      "invisible absolute bottom-0 right-0 top-0 flex items-center justify-center pr-0.5 text-carbon-500",
    actionButton:
      "flex h-full w-6 items-center justify-center ring-blue-500 hover:text-black focus:ring-2",
    otherOptionsContainer:
      "flex flex-col whitespace-nowrap bg-white text-black shadow",
    otherOptionsButton:
      "py-1.5 pl-4 pr-6 text-left text-sm text-carbon-800 ring-blue-500 focus-within:bg-carbon-100 hover:bg-carbon-100 focus-visible:ring-2",
  },
  variants: {
    hidden: {
      true: { otherOptionsContainer: "hidden" },
    },
    highlighted: {
      true: { container: "visible" },
    },
  },
});

export const NavItemActions = ({
  item,
  level,
  isHighlighted,
  alternateCourseId = null,
  setAlternateCourse,
  otherOptionsOpen,
  setOtherOptionsOpen,
  otherVersionsOpen,
  setOtherVersionsOpen,
  canEditCourse = false,
}: {
  item: CourseNavigationItem;
  level: Level;
  isHighlighted: boolean;
  alternateCourseId?: number | null;
  setAlternateCourse?: (
    course: CourseNavigationItem & Pick<Course, "region_id" | "course_type">
  ) => void;
  otherOptionsOpen: boolean;
  setOtherOptionsOpen: (id: number | null) => void;
  otherVersionsOpen?: boolean;
  setOtherVersionsOpen?: (id: number | null) => void;
  canEditCourse?: boolean;
}) => {
  if (!["courses", "chapters", "lessons"].includes(level)) return null;

  const handleShowOtherOptionsClick = () => {
    setOtherOptionsOpen(otherOptionsOpen ? null : item.id);
  };

  const handleShowOtherVersionsClick = () => {
    setOtherVersionsOpen?.(otherVersionsOpen ? null : item.id);
  };

  return (
    <div
      className={
        navItemActionsVariants().container({
          highlighted: isHighlighted,
        }) + " nav-item-actions"
      }
      onClick={(e) => e.stopPropagation()}
    >
      {level === "courses" && (
        <CourseActions
          course={item as CourseNavItem}
          otherOptionsOpen={otherOptionsOpen}
          onOtherOptionsClick={handleShowOtherOptionsClick}
          otherVersionsOpen={otherVersionsOpen}
          onOtherVersionsClick={handleShowOtherVersionsClick}
          alternateCourseId={alternateCourseId}
          setAlternateCourse={setAlternateCourse}
        />
      )}
      {level === "chapters" && (
        <ChapterActions
          item={item as ChaptersNavItem}
          otherOptionsOpen={otherOptionsOpen}
          onOtherOptionsClick={handleShowOtherOptionsClick}
          otherVersionsOpen={otherVersionsOpen}
          onOtherVersionsClick={handleShowOtherVersionsClick}
          canEditCourse={canEditCourse}
        />
      )}
      {level === "lessons" && (
        <LessonActions
          item={item as LessonsNavItem}
          otherOptionsOpen={otherOptionsOpen}
          onOtherOptionsClick={handleShowOtherOptionsClick}
          otherVersionsOpen={otherVersionsOpen}
          onOtherVersionsClick={handleShowOtherVersionsClick}
          canEditCourse={canEditCourse}
        />
      )}
    </div>
  );
};

type CommonNavItemActionProps = {
  otherOptionsOpen: boolean;
  onOtherOptionsClick: () => void;
  otherVersionsOpen?: boolean;
  onOtherVersionsClick?: () => void;
};

type CourseNavItem = Extract<
  CourseNavigationItem,
  { type: "course" | "sample_course" | "private_course" }
>;

const CourseActions = ({
  course,
  alternateCourseId,
  otherOptionsOpen,
  onOtherOptionsClick,
  otherVersionsOpen,
  onOtherVersionsClick,
  setAlternateCourse,
}: {
  course: CourseNavItem;
  alternateCourseId: number | null;
  setAlternateCourse?: (
    course: CourseNavigationItem & Pick<Course, "region_id" | "course_type">
  ) => void;
} & CommonNavItemActionProps) => {
  const styles = navItemActionsVariants();

  return (
    <>
      <ShowProgressButton href={`${getRootSF3URL()}/course/${course.id}`} />
      <NavCourseVersionSwitch
        item={course}
        open={!!otherVersionsOpen}
        onOpenChange={onOtherVersionsClick}
        alternateCourseId={alternateCourseId}
        setAlternateCourse={setAlternateCourse}
      />
      <Dropdown.Root open={otherOptionsOpen} modal={false}>
        <Dropdown.Trigger asChild>
          <OtherOptionsButton onClick={onOtherOptionsClick} />
        </Dropdown.Trigger>
        <OtherOptionsDropdown
          otherOptionsOpen={otherOptionsOpen}
          onOtherOptionsClick={onOtherOptionsClick}
          otherVersionsOpen={otherVersionsOpen}
          onOtherVersionsClick={onOtherVersionsClick}
        >
          {course.canEdit && (
            <Dropdown.Item asChild>
              <a
                className={styles.otherOptionsButton()}
                href={`${getRootSF3URL()}/construct/course/${course.id}`}
              >
                Edit Course
              </a>
            </Dropdown.Item>
          )}
          {course.canViewLTILinks && (
            <CopyLTILinkButton url={`${getRootSF3URL()}/course/${course.id}`} />
          )}
          <Dropdown.Item asChild>
            <a
              className={styles.otherOptionsButton()}
              href={`${getRootSF3URL()}/course/${course.id}/outline`}
            >
              View Course Outline
            </a>
          </Dropdown.Item>
        </OtherOptionsDropdown>
      </Dropdown.Root>
    </>
  );
};

type ChaptersNavItem =
  | Extract<CourseNavigationItem, { type: "chapter" }>
  | Extract<CourseNavigationItem, { type: "project" }>
  | Extract<CourseNavigationItem, { type: "quiz" }>;

const ChapterActions = ({
  item,
  otherOptionsOpen,
  onOtherOptionsClick,
  otherVersionsOpen,
  onOtherVersionsClick,
  canEditCourse,
}: {
  item: ChaptersNavItem;
  canEditCourse: boolean;
} & CommonNavItemActionProps) => {
  const styles = navItemActionsVariants();

  if (!["chapter", "project", "quiz"].includes(item.type)) {
    return null;
  }

  const showBtns = {
    showEditCourseBtn: item.type === "chapter" && canEditCourse,
    showEditQuizBtn: item.type === "quiz" && canEditCourse,
    showCopyLTILinkBtn: item.canViewLTILinks,
  };
  const showOtherOptions = Object.values(showBtns).some(Boolean);

  return (
    <>
      {["chapter", "project"].includes(item.type) && (
        <ShowProgressButton
          href={`${getRootSF3URL()}/${item.type}/${item.id}`}
        />
      )}
      {showOtherOptions && (
        <Dropdown.Root open={otherOptionsOpen} modal={false}>
          <Dropdown.Trigger asChild>
            <OtherOptionsButton onClick={onOtherOptionsClick} />
          </Dropdown.Trigger>
          <OtherOptionsDropdown
            otherOptionsOpen={otherOptionsOpen}
            onOtherOptionsClick={onOtherOptionsClick}
            otherVersionsOpen={otherVersionsOpen}
            onOtherVersionsClick={onOtherVersionsClick}
          >
            {item.type === "chapter" && showBtns.showEditCourseBtn && (
              <Dropdown.Item asChild>
                <a
                  className={styles.otherOptionsButton()}
                  href={`${getRootSF3URL()}/construct/course/${item.courseId}`}
                >
                  Edit Course
                </a>
              </Dropdown.Item>
            )}
            {showBtns.showEditQuizBtn && (
              <Dropdown.Item asChild>
                <a
                  className={styles.otherOptionsButton()}
                  href={`${env.NEXT_PUBLIC_APP_URL}/construct/quiz/${item.id}?group=0`}
                  target="_self"
                >
                  Edit Quiz
                </a>
              </Dropdown.Item>
            )}
            {showBtns.showCopyLTILinkBtn && (
              <CopyLTILinkButton
                url={`${getRootSF3URL()}/${
                  item.type === "quiz" ? "assess" : item.type
                }/${item.id}`}
              />
            )}
          </OtherOptionsDropdown>
        </Dropdown.Root>
      )}
    </>
  );
};

type LessonsNavItem =
  | Extract<CourseNavigationItem, { type: "region" | "lesson" | "custom" }>
  | Extract<CourseNavigationItem, { type: "project" }>
  | Extract<CourseNavigationItem, { type: "quiz" }>;

const LessonActions = ({
  item,
  otherOptionsOpen,
  onOtherOptionsClick,
  otherVersionsOpen,
  onOtherVersionsClick,
  canEditCourse,
}: {
  item: LessonsNavItem;
  canEditCourse: boolean;
} & CommonNavItemActionProps) => {
  const styles = navItemActionsVariants();
  const lessonBuilderEnabled = useAppStore((state) =>
    state.isFeatureEnabled("lesson_builder")
  );

  const showBtns = {
    showEditLessonBtn: item.type === "lesson" && canEditCourse,
    showEditQuizBtn: item.type === "quiz" && canEditCourse,
    showCopyLTILinkBtn: item.canViewLTILinks,
  };
  const showOtherOptions = Object.values(showBtns).some(Boolean);

  return (
    <>
      {["lesson", "project"].includes(item.type) && (
        <ShowProgressButton
          href={`${getRootSF3URL()}/${item.type}/${item.id}`}
        />
      )}
      {showOtherOptions && (
        <Dropdown.Root open={otherOptionsOpen} modal={false}>
          <Dropdown.Trigger asChild>
            <OtherOptionsButton onClick={onOtherOptionsClick} />
          </Dropdown.Trigger>
          <OtherOptionsDropdown
            otherOptionsOpen={otherOptionsOpen}
            onOtherOptionsClick={onOtherOptionsClick}
            otherVersionsOpen={otherVersionsOpen}
            onOtherVersionsClick={onOtherVersionsClick}
          >
            {showBtns.showEditLessonBtn && (
              <Dropdown.Item asChild>
                <a
                  className={styles.otherOptionsButton()}
                  href={`${getRootSF3URL()}/lesson/${item.id}?edit=${item.id}`}
                >
                  Edit Lesson
                </a>
              </Dropdown.Item>
            )}
            {showBtns.showEditLessonBtn && lessonBuilderEnabled && (
              <Dropdown.Item asChild>
                <a
                  className={styles.otherOptionsButton()}
                  href={`${env.NEXT_PUBLIC_APP_URL}/lesson/${item.id}/builder`}
                >
                  Edit in New Lesson Builder
                </a>
              </Dropdown.Item>
            )}
            {showBtns.showEditQuizBtn && (
              <Dropdown.Item asChild>
                <a
                  className={styles.otherOptionsButton()}
                  href={`${env.NEXT_PUBLIC_APP_URL}/construct/quiz/${item.id}?group=0`}
                >
                  Edit Quiz
                </a>
              </Dropdown.Item>
            )}
            {showBtns.showCopyLTILinkBtn && (
              <CopyLTILinkButton
                url={`${getRootSF3URL()}/${
                  item.type === "quiz" ? "assess" : item.type
                }/${item.id}`}
              />
            )}
          </OtherOptionsDropdown>
        </Dropdown.Root>
      )}
    </>
  );
};

const ShowProgressButton = ({ href }: { href: string }) => {
  return (
    <Tooltip
      title="View progress"
      size="sm"
      contentProps={{ side: "top" }}
      portalProps={{
        container: portalContainer(),
      }}
    >
      <a className={navItemActionsVariants().actionButton()} href={href}>
        <ProgressIcon width="14" height="14" />
      </a>
    </Tooltip>
  );
};

const OtherOptionsButton = forwardRef<
  HTMLButtonElement,
  { onClick: () => void }
>(({ onClick }, ref) => {
  return (
    <Tooltip
      title={`More options`}
      size="sm"
      contentProps={{ side: "top" }}
      portalProps={{
        container: portalContainer(),
      }}
    >
      <button
        type="button"
        className={navItemActionsVariants().actionButton()}
        onClick={onClick}
        ref={ref}
      >
        <EllipsisIcon width="14" height="14" />
      </button>
    </Tooltip>
  );
});
OtherOptionsButton.displayName = "OtherOptionsButton";

type OtherOptionsDropdownProps = PropsWithChildren<
  Omit<CommonNavItemActionProps, "triggerRef">
>;

const OtherOptionsDropdown = ({
  otherOptionsOpen,
  onOtherOptionsClick,
  children,
}: OtherOptionsDropdownProps) => {
  return (
    <Dropdown.Portal container={portalContainer()}>
      <Dropdown.Content
        align="start"
        loop={true}
        onInteractOutside={onOtherOptionsClick}
        className={`nav-item-actions z-50 ${otherOptionsOpen ? "open" : ""}`}
      >
        <div
          className={navItemActionsVariants().otherOptionsContainer({
            hidden: !otherOptionsOpen,
          })}
          onKeyDown={(e) => {
            if (e.key === "Escape") {
              e.preventDefault();
              onOtherOptionsClick();
            }
          }}
        >
          {children}
        </div>
      </Dropdown.Content>
    </Dropdown.Portal>
  );
};

const CopyLTILinkButton = ({ url }: { url: string }) => {
  const styles = navItemActionsVariants();
  const [ltiLinkCopied, setLTILinkCopied] = useState(false);

  const handleCopyLTILink = (value: string) => {
    if (ltiLinkCopied) return;
    copyToClipboard(value).then(() => {
      setLTILinkCopied(true);
      setTimeout(() => {
        setLTILinkCopied(false);
      }, 2000);
    });
  };

  return (
    <Dropdown.Item asChild>
      <button
        type="button"
        className={styles.otherOptionsButton()}
        onClick={() => {
          const ltiLink = generateLTILink(url);
          handleCopyLTILink(ltiLink);
        }}
      >
        {!ltiLinkCopied ? (
          "Copy LTI Link"
        ) : (
          <span className="flex flex-row gap-1">
            <CheckIcon width={14} />
            Copied!
          </span>
        )}
      </button>
    </Dropdown.Item>
  );
};

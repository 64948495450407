import { env } from "$env";
import { CourseNavigationItem } from "$hooks/useCourseNavigation";
import { getRootSF3URL } from "$lib/navigation/routing";
import { UseComboboxReturnValue } from "downshift";
import { KeyboardEventHandler } from "react";

/**
 * Checks if the app this is being called from the sf3 app
 */
export function checkIsSf3App() {
  return (
    typeof window !== "undefined" &&
    !window.location.href.startsWith(env.NEXT_PUBLIC_APP_URL)
  );
}

/**
 * Given a relative path, and a boolean that indicates if the relative
 * path is from sf3 or sf4, returns the absolute href
 */
export function getAbsoluteHref(relativeHref: string, sf3Href: boolean) {
  if (relativeHref.startsWith("http")) {
    return relativeHref;
  }

  if (sf3Href) {
    return getRootSF3URL() + relativeHref;
  }

  return env.NEXT_PUBLIC_APP_URL + relativeHref;
}

export const createOnKeyDownHandler =
  ({
    subMenuOpen,
    closeSubMenu,
    closeMenu,
    onGoBack,
    getItemProps,
    totalItems,
    highlightedIndex,
    setHighlightedIndex,
    selectedItemIndex,
    originalOnKeyDown,
  }: {
    subMenuOpen: boolean;
    closeSubMenu: () => void;
    closeMenu: () => void;
    onGoBack: () => void;
    getItemProps: UseComboboxReturnValue<CourseNavigationItem>["getItemProps"];
    totalItems: number;
    highlightedIndex: number;
    setHighlightedIndex: (index: number) => void;
    selectedItemIndex: number;
    originalOnKeyDown?: KeyboardEventHandler<HTMLElement>;
  }): KeyboardEventHandler<HTMLElement> =>
  (e) => {
    let el: Element | undefined | null = undefined;
    const input = e.currentTarget.querySelector("input");
    if (e.key === "ArrowDown" && highlightedIndex === totalItems - 1) {
      e.preventDefault();
      e.stopPropagation();
    } else if (e.key === "ArrowUp" && highlightedIndex === 0) {
      setHighlightedIndex(-1);
      e.preventDefault();
      e.stopPropagation();
    } else if (e.key === "ArrowUp" && highlightedIndex === -1) {
      closeMenu();
    } else if (
      e.key === "ArrowLeft" &&
      document.activeElement === input &&
      !subMenuOpen
    ) {
      onGoBack();
    } else if (e.key === "Tab") {
      closeMenu();
    } else if (subMenuOpen) {
      if (e.key === "Escape" || e.key === "ArrowLeft") {
        closeSubMenu();
      }
    } else if (
      e.key === "ArrowRight" &&
      (el = e.currentTarget.querySelector(".nav-item-actions.visible")) &&
      document.activeElement
    ) {
      if (el.contains(document.activeElement)) {
        const sibling = document.activeElement.nextElementSibling;
        if (sibling) {
          (sibling as HTMLElement).focus();
        } else {
          (document.activeElement as HTMLElement).click();
        }
      } else {
        (el.children[0] as HTMLElement)?.focus();
      }
    } else if (
      e.key === "ArrowLeft" &&
      (el = e.currentTarget.querySelector(".nav-item-actions.visible")) &&
      document.activeElement &&
      el.contains(document.activeElement)
    ) {
      const sibling = document.activeElement.previousElementSibling;
      if (sibling) {
        (sibling as HTMLElement).focus();
      } else {
        input?.focus();
      }
    } else if (
      e.key === "ArrowRight" &&
      document.activeElement === input &&
      selectedItemIndex >= 0 &&
      (el = document.getElementById(
        getItemProps({ item: {} as any, index: selectedItemIndex }).id
      ))
    ) {
      (el as HTMLElement).click();
    } else if (
      e.key === "Enter" &&
      (el = e.currentTarget.querySelector(".nav-item-actions.visible")) &&
      document.activeElement &&
      el.contains(document.activeElement)
    ) {
      e.preventDefault();
      (document.activeElement as HTMLElement).click();
    } else if (
      e.key === "Enter" &&
      (el = e.currentTarget.querySelector("a.nav-item-highlighted"))
    ) {
      (el as HTMLElement).click();
    } else {
      if (input && input !== e.target) {
        input.focus();
      }
      originalOnKeyDown?.(e);
    }
  };

import { FeatureFlag } from "$lib/featureFlag";
import { AccessibilitySettings } from "$lib/user/accessibilitySettings";
import {
  EmailSubscriptionLevelName,
  EmailSubscriptionType,
} from "$lib/user/emailSubscriptions";
import { ViewAsData } from "$server/data/user/user";
import { User } from "@studyforge/prisma";
import { createStore } from "zustand/vanilla";

const APP_STORE_SESSION_KEY = "sf-app-storage";

export type AppState = {
  currentUser: Pick<
    User,
    "id" | "firstname" | "lastname" | "institution_id"
  > & {
    isSFLite: boolean;
    canSeeLTILinks: boolean;
    preferences: {
      is_beta_user: boolean;
      email_subscriptions: Partial<
        Record<EmailSubscriptionType, EmailSubscriptionLevelName>
      >;
      acc_settings: AccessibilitySettings;
    };
    enabledFeatures: FeatureFlag[];
  };
  isStudent: boolean;
  isViewAsClientSide: boolean;
  viewAsUser: ViewAsData["viewAsUser"] | null;
  viewAsGroup: ViewAsData["viewAsGroup"] | null;
  viewAsScopes: Array<"none" | "groups" | "users">;
  pageHasProgress: boolean;
  isViewingProgress: boolean | null;
  region: { id: number } | null;
  stream: { id: number } | "private" | "sample" | null;
  course: { id: number; name: string } | null;
  chapter: { id: number; name: string } | null;
  project: { id: number; name: string } | null;
  lesson: { id: number; name: string } | null;
  quiz: { id: number; name: string } | null;
  courseIdsUserCanEdit: Set<number>;
  timeZone: string | null;
  mathJaxLoaded: boolean;
} & SF3ShimState;

export type ViewAsScopes = AppState["viewAsScopes"];

export type AppActions = {
  addCourseIdUserCanEdit: (courseId: number) => void;
  setViewAsScopes: (
    viewAsScope: AppState["viewAsScopes"],
    isViewingAsClientSide?: AppState["isViewAsClientSide"]
  ) => void;
  setViewAsClientSide: (isViewAsClientSide?: boolean) => void;
  setViewAsUser: (args: {
    user: AppState["viewAsUser"];
    group?: AppState["viewAsGroup"];
  }) => void;
  setViewAsGroup: (group: AppState["viewAsGroup"]) => void;
  setSelectedContent: (
    args: Partial<{
      region: AppState["region"];
      stream: AppState["stream"];
      course: AppState["course"];
      chapter: AppState["chapter"];
      project: AppState["project"];
      lesson: AppState["lesson"];
      quiz: AppState["quiz"];
    }>
  ) => void;
  setMathJaxLoaded: () => void;
  setIsViewingProgress: (isViewingProgress: boolean) => void;
  clearViewAs: () => void;
  setAccessibilitySettings: (settings: AccessibilitySettings) => void;
  setAppState: (state: Partial<AppState>) => void;
  isFeatureEnabled: (flagName: FeatureFlag) => boolean;
} & SF3ShimActions;

/**
 * SF3 Shim to be removed after Lesson Builder is released
 * @see apps/sf3/src/AppBundle/Resources/typescript/SF/ViewModel/Navigation/NavigationElement.tsx
 * @see apps/sf3/src/AppBundle/Resources/typescript/SF/ViewModel/Navigation/NavigationStateManager.tsx
 * @deprecated
 */
type SF3ShimState = {
  editingLessonId: number | null;
  pageWithoutNavigation: boolean;
  isPageLoading: boolean;
};
type SF3ShimActions = {
  setEditingLessonId: (lessonId: number | null) => void;
  setIsPageLoading: (isPageLoading: boolean) => void;
};

export type AppStore = AppState & AppActions;

export const defaultInitState: Omit<AppState, "currentUser" | "isStudent"> = {
  viewAsUser: null,
  viewAsGroup: null,
  isViewAsClientSide: false,
  viewAsScopes: ["none"],
  isViewingProgress: true,
  pageHasProgress: false,
  region: { id: 1 },
  stream: null,
  course: null,
  chapter: null,
  project: null,
  lesson: null,
  quiz: null,
  courseIdsUserCanEdit: new Set(),
  mathJaxLoaded: false,
  timeZone: null,
  editingLessonId: null,
  isPageLoading: false,
  pageWithoutNavigation: false,
};

export const createAppStore = ({
  isPreview,
  ...initState
}: Partial<AppState> & {
  currentUser: AppState["currentUser"];
  isStudent: AppState["isStudent"];
  isPreview?: boolean;
}) => {
  const store = createStore<AppStore>()((set, get) => ({
    ...defaultInitState,
    ...initState,
    addCourseIdUserCanEdit: (courseId) =>
      set((state) => ({
        courseIdsUserCanEdit: new Set(state.courseIdsUserCanEdit).add(courseId),
      })),
    setViewAsClientSide: (isViewAsClientSide = true) =>
      set((state) => ({ ...state, isViewAsClientSide })),
    setViewAsUser: ({ user, group = null }) =>
      set((state) => ({
        ...state,
        viewAsUser: user,
        viewAsGroup: group,
        isViewingProgress: true,
      })),
    setViewAsGroup: (viewAsGroup) =>
      set((state) => ({
        ...state,
        viewAsGroup,
        viewAsUser: null,
        isViewingProgress: true,
      })),
    setSelectedContent: (content) => set((state) => ({ ...state, ...content })),
    setViewAsScopes: (viewAsScopes, isViewAsClientSide) =>
      set((state) => ({
        ...state,
        viewAsScopes: Array.from(new Set(viewAsScopes)),
        isViewAsClientSide: isViewAsClientSide ?? state.isViewAsClientSide,
      })),
    setIsViewingProgress: (isViewingProgress) =>
      set((state) => ({
        ...state,
        isViewingProgress,
        viewAsUser:
          !isViewingProgress && state.viewAsUser?.id === state.currentUser?.id
            ? null
            : state.viewAsUser,
      })),
    setMathJaxLoaded: () => set((state) => ({ ...state, mathJaxLoaded: true })),
    clearViewAs: () =>
      set((state) => ({
        ...state,
        viewAsUser: null,
        viewAsGroup: null,
        isViewingProgress: false,
      })),
    setEditingLessonId: (lessonId) =>
      set((state) => {
        if (state.pageWithoutNavigation) {
          return state;
        }
        return { ...state, editingLessonId: lessonId };
      }),
    setIsPageLoading: (isPageLoading) =>
      set((state) => ({
        ...state,
        isPageLoading,
      })),
    setAccessibilitySettings: (settings: AccessibilitySettings) =>
      set((state) => ({
        ...state,
        currentUser: {
          ...state.currentUser,
          preferences: {
            ...state.currentUser.preferences,
            acc_settings: settings,
          },
        },
      })),
    setAppState: (state) => set(state),
    isFeatureEnabled: (featureName: FeatureFlag) => {
      const state = get();
      return state.currentUser?.enabledFeatures.includes(featureName) || false;
    },
  }));
  if (typeof window !== "undefined" && window.inSF3 && !isPreview) {
    (window as any).sf4AppStore = store;
  }
  return store;
};

type AppStorePersistStorage = Pick<
  AppStore,
  "viewAsUser" | "viewAsGroup" | "isViewingProgress" | "viewAsScopes"
>;

export function getStoreValueFromSessionStorage<
  T extends keyof AppStorePersistStorage,
>(name: T) {
  try {
    const str = sessionStorage.getItem(APP_STORE_SESSION_KEY);
    if (!str) return null;

    const obj = JSON.parse(str) as {
      state: AppStorePersistStorage;
      version: number;
    };
    return obj.state[name];
  } catch {
    return null;
  }
}

import { prependBasePath } from "$lib/navigation/prependBasePath";

export function nativePush(url: string) {
  if (typeof window === "undefined") {
    return;
  }

  url = prependBasePath(url);

  if (url !== window.location.pathname + window.location.search) {
    window.history.pushState(
      { ...window.history.state, as: url, url },
      "",
      url
    );
  }
}

export function nativeReplace(url: string) {
  if (typeof window === "undefined") {
    return;
  }

  url = prependBasePath(url);

  if (url !== window.location.pathname + window.location.search) {
    window.history.replaceState(
      { ...window.history.state, as: url, url },
      "",
      url
    );
  }
}

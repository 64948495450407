export const COURSE_TYPE = {
  STUDYFORGE: 1,
  TEACHER: 2,
  PRIVATE: 3,
  SAMPLE: 4,
  INSTITUTION: 5,
  PARTNER: 6,
};

export const COURSE_TYPE_INFO = {
  [COURSE_TYPE.STUDYFORGE]: {
    number: COURSE_TYPE.STUDYFORGE,
    name: "StudyForge Course",
    code: "SF",
    alias: "StudyForge",
    order: 1,
  },
  [COURSE_TYPE.TEACHER]: {
    number: COURSE_TYPE.TEACHER,
    name: "Teacher Course",
    code: "T",
    alias: "Teacher",
    order: 4,
  },
  [COURSE_TYPE.PRIVATE]: {
    number: COURSE_TYPE.PRIVATE,
    name: "Private Course",
    code: "P",
    alias: "Private",
    order: 5,
  },
  [COURSE_TYPE.SAMPLE]: {
    number: COURSE_TYPE.SAMPLE,
    name: "Sample Course",
    code: "SAMPLE",
    alias: "Sample",
    order: 6,
  },
  [COURSE_TYPE.INSTITUTION]: {
    number: COURSE_TYPE.INSTITUTION,
    name: "Institution Course",
    code: "I",
    alias: "Institution",
    order: 3,
  },
  [COURSE_TYPE.PARTNER]: {
    number: COURSE_TYPE.PARTNER,
    name: "Partner Course",
    code: "PA",
    alias: "Partner",
    order: 2,
  },
} as const;

export const MAX_NUMBER_OF_PROJECTS = 6;

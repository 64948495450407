import { env } from "$env";
import { useSafeSearchParams } from "$hooks/useSafeSearchParams";
import { api } from "$lib/api";
import { nativeReplace } from "$lib/navigation";
import { AppState, type AppStore } from "$store/index";
import isEqual from "fast-deep-equal";
import { type ReactNode, createContext, useEffect, useRef } from "react";
import { z } from "zod";
import { type StoreApi } from "zustand";

export const AppStoreContext = createContext<StoreApi<AppStore> | null>(null);

export type AppStoreProviderProps = {
  children: ReactNode;
  changeUrl: (url: string, options: { type: "push" | "replace" }) => void;
  isPreview?: boolean;
  appStore: StoreApi<AppStore>;
};

export const AppStoreProvider = ({
  children,
  changeUrl,
  appStore,
  isPreview,
}: AppStoreProviderProps) => {
  const storeRef = useRef(appStore);

  const { viewAsUser, viewAsGroup, isViewingProgress } = appStore.getState();

  useEffect(() => {
    storeRef.current.subscribe(getAppStoreChangeListener(changeUrl));
  }, [changeUrl]);

  const params = useSafeSearchParams({
    group: z.coerce.number().int().optional().catch(undefined),
    user: z.coerce.number().int().optional().catch(undefined),
    progress: z
      .enum(["true", "false"])
      .optional()
      .catch(undefined)
      .transform((value) => {
        if (value) return value === "true";
      }),
  });
  const { data: editableCourseIds, isPending: isLoadingEditableCourseIds } =
    api.course.findAllEditableCourseIds.useQuery(
      {},
      { staleTime: Infinity, enabled: !isPreview }
    );
  const { data: viewAsData, isPending } = api.user.getNavbarUserInfo.useQuery(
    params,
    {
      enabled: (!!params.group || !!params.user) && !isPreview,
      staleTime: Infinity,
      initialData: {
        viewAsUser,
        viewAsGroup,
        isViewingProgress: isViewingProgress ?? false,
      },
    }
  );
  useEffect(() => {
    if (!isLoadingEditableCourseIds) {
      storeRef.current!.setState((state) => ({
        ...state,
        courseIdsUserCanEdit: new Set(editableCourseIds ?? []),
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingEditableCourseIds]);

  useEffect(() => {
    if (!isPending) {
      storeRef.current!.setState((state) => ({
        ...state,
        viewAsGroup: viewAsData?.viewAsGroup,
        viewAsUser: viewAsData?.viewAsUser,
        isViewingProgress: viewAsData?.isViewingProgress,
      }));
    }
    // We just need to run this once when the page loads
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPending]);

  useEffect(() => {
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    // Set the user timezone cookie with a duration of 6 months
    document.cookie = `sf_user-tz=${userTimeZone}; Path=/; Max-Age=15768000; SameSite=None; Secure`;

    if (storeRef.current) {
      storeRef.current.setState({ timeZone: userTimeZone });
    }
  }, []);

  return (
    <AppStoreContext.Provider value={storeRef.current}>
      {children}
    </AppStoreContext.Provider>
  );
};

function getUrlFromParams(paramsStr: string) {
  let url = window.location.pathname;
  if (paramsStr) {
    url += "?" + paramsStr;
  }
  return url;
}

function getAppStoreChangeListener(
  changeUrl: AppStoreProviderProps["changeUrl"]
) {
  return (state: AppState, prevState: AppState) => {
    const viewAsScopesChanged = state.viewAsScopes !== prevState.viewAsScopes;
    const viewAsGroupChanged = state.viewAsGroup !== prevState.viewAsGroup;
    const viewAsUserChanged = state.viewAsUser !== prevState.viewAsUser;
    const isViewingProgressChanged =
      state.isViewingProgress !== prevState.isViewingProgress;

    if (
      !viewAsGroupChanged &&
      !viewAsUserChanged &&
      !viewAsScopesChanged &&
      !isViewingProgressChanged
    ) {
      return;
    }

    const {
      currentUser,
      viewAsScopes,
      viewAsGroup,
      viewAsUser,
      isViewingProgress,
      isViewAsClientSide,
    } = state;

    if (isEqual(viewAsScopes, ["none"])) {
      // Remove the group and user search params with a replace
      const params = new URLSearchParams(window.location.search.toString());
      params.delete("group");
      params.delete("user");
      nativeReplace(getUrlFromParams(params.toString()));
      return;
    }

    if (viewAsScopes.includes("groups") || viewAsScopes.includes("users")) {
      const params = new URLSearchParams(window.location.search.toString());
      const viewingAsMyself = currentUser.id === viewAsUser?.id;

      if (viewAsScopes.includes("none")) {
        // isViewingProgress defaults to true if viewing as a group or user
        // and defaults to false otherwise
        const defaultIsViewingProgress = !!viewAsGroup || !!viewAsUser;
        const value =
          isViewingProgress === null
            ? defaultIsViewingProgress
            : isViewingProgress;

        if (value !== defaultIsViewingProgress || viewingAsMyself) {
          params.set("progress", String(value));
        } else {
          params.delete("progress");
        }
      } else {
        params.delete("progress");
      }

      if (viewAsGroup && (!viewAsScopes.includes("none") || !viewingAsMyself)) {
        params.set("group", String(viewAsGroup.id));
      } else {
        params.delete("group");
      }

      if (
        viewAsUser &&
        viewAsScopes.includes("users") &&
        (!viewAsScopes.includes("none") || !viewingAsMyself)
      ) {
        params.set("user", String(viewAsUser.id));
      } else {
        params.delete("user");
      }

      const currentParams = Object.fromEntries(
        new URLSearchParams(window.location.search.toString()).entries()
      );
      if (isEqual(currentParams, Object.fromEntries(params.entries()))) {
        return;
      }

      const apiUrl = new URL(`${env.NEXT_PUBLIC_APP_URL}/api/stickyparams`);
      apiUrl.search = params.toString();
      void fetch(apiUrl.href, { method: "POST" });

      const paramsStr = params.toString();

      let url = getUrlFromParams(paramsStr);
      if (isViewAsClientSide) {
        changeUrl(url, { type: "replace" });
      } else if (!viewAsScopesChanged || !viewAsScopes.includes("none")) {
        // If this is a page change (viewAsScopeChanged === true), and viewAsScopes includes "none",
        // we want to prevent the router.push
        changeUrl(url, { type: "push" });
      }
      return;
    }
  };
}

import { Ref, forwardRef } from "react";
import { IconProps, IconWeight } from "./index";

const SvgComponent = (
  {
    weight = "regular",
    title,
    ...svgProps
  }: IconProps & {
    weight?: IconWeight;
  },
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="24"
    fill="currentColor"
    ref={ref}
    {...svgProps}
  >
    {title && <title>{title}</title>}
    <Content weight={weight} />
  </svg>
);

const Content = ({ weight }: { weight: IconWeight }) => {
  switch (weight) {
    case "thin":
      return (
        <path d="M21.2653 7.01534L9.26528 19.0153C9.23045 19.0502 9.18909 19.0779 9.14357 19.0967C9.09804 19.1156 9.04925 19.1253 8.99996 19.1253C8.95068 19.1253 8.90189 19.1156 8.85636 19.0967C8.81084 19.0779 8.76948 19.0502 8.73465 19.0153L3.48465 13.7653C3.41429 13.695 3.37476 13.5995 3.37476 13.5C3.37476 13.4005 3.41429 13.3051 3.48465 13.2347C3.55502 13.1643 3.65045 13.1248 3.74996 13.1248C3.84948 13.1248 3.94491 13.1643 4.01528 13.2347L8.99996 18.2194L20.7347 6.48471C20.805 6.41435 20.9005 6.37482 21 6.37482C21.0995 6.37482 21.1949 6.41435 21.2653 6.48471C21.3356 6.55508 21.3752 6.65051 21.3752 6.75003C21.3752 6.84954 21.3356 6.94497 21.2653 7.01534Z" />
      );

    case "light":
      return (
        <path d="M21.3975 7.14751L9.39751 19.1475C9.29204 19.2528 9.14907 19.312 9.00001 19.312C8.85094 19.312 8.70798 19.2528 8.60251 19.1475L3.35251 13.8975C3.25315 13.7909 3.19905 13.6498 3.20163 13.5041C3.2042 13.3584 3.26323 13.2194 3.36629 13.1163C3.46935 13.0132 3.60839 12.9542 3.75411 12.9516C3.89984 12.9491 4.04088 13.0031 4.14751 13.1025L9.00001 17.9541L20.6025 6.35251C20.7091 6.25315 20.8502 6.19905 20.9959 6.20163C21.1416 6.2042 21.2807 6.26323 21.3837 6.36629C21.4868 6.46935 21.5458 6.60839 21.5484 6.75412C21.551 6.89984 21.4969 7.04088 21.3975 7.14751Z" />
      );

    case "regular":
      return (
        <path d="M21.5307 7.28062L9.53068 19.2806C9.46102 19.3503 9.3783 19.4057 9.28726 19.4434C9.19621 19.4812 9.09861 19.5006 9.00005 19.5006C8.90149 19.5006 8.80389 19.4812 8.71285 19.4434C8.6218 19.4057 8.53908 19.3503 8.46943 19.2806L3.21943 14.0306C3.0787 13.8899 2.99963 13.699 2.99963 13.5C2.99963 13.301 3.0787 13.1101 3.21943 12.9694C3.36016 12.8286 3.55103 12.7496 3.75005 12.7496C3.94907 12.7496 4.13995 12.8286 4.28068 12.9694L9.00005 17.6897L20.4694 6.21936C20.6102 6.07863 20.801 5.99957 21.0001 5.99957C21.1991 5.99957 21.3899 6.07863 21.5307 6.21936C21.6714 6.36009 21.7505 6.55097 21.7505 6.74999C21.7505 6.94901 21.6714 7.13988 21.5307 7.28062Z" />
      );

    case "bold":
      return (
        <path d="M21.7959 7.54591L9.7959 19.5459C9.69138 19.6508 9.56719 19.734 9.43044 19.7908C9.2937 19.8476 9.14709 19.8768 8.99902 19.8768C8.85096 19.8768 8.70435 19.8476 8.5676 19.7908C8.43085 19.734 8.30666 19.6508 8.20215 19.5459L2.95215 14.2959C2.8475 14.1913 2.76449 14.067 2.70785 13.9303C2.65122 13.7936 2.62207 13.647 2.62207 13.499C2.62207 13.351 2.65122 13.2045 2.70785 13.0678C2.76449 12.931 2.8475 12.8068 2.95215 12.7022C3.05679 12.5975 3.18103 12.5145 3.31776 12.4579C3.45448 12.4012 3.60103 12.3721 3.74902 12.3721C3.89702 12.3721 4.04356 12.4012 4.18029 12.4579C4.31702 12.5145 4.44125 12.5975 4.5459 12.7022L8.99996 17.1562L20.204 5.95404C20.4154 5.74269 20.702 5.62396 21.0009 5.62396C21.2998 5.62396 21.5864 5.74269 21.7978 5.95404C22.0091 6.16538 22.1278 6.45203 22.1278 6.75091C22.1278 7.0498 22.0091 7.33644 21.7978 7.54779L21.7959 7.54591Z" />
      );

    case "fill":
      return (
        <path d="M20.25 3.75H3.75C3.35218 3.75 2.97064 3.90804 2.68934 4.18934C2.40804 4.47064 2.25 4.85218 2.25 5.25V18.75C2.25 19.1478 2.40804 19.5294 2.68934 19.8107C2.97064 20.092 3.35218 20.25 3.75 20.25H20.25C20.6478 20.25 21.0294 20.092 21.3107 19.8107C21.592 19.5294 21.75 19.1478 21.75 18.75V5.25C21.75 4.85218 21.592 4.47064 21.3107 4.18934C21.0294 3.90804 20.6478 3.75 20.25 3.75ZM19.2806 8.03063L10.2806 17.0306C10.211 17.1004 10.1283 17.1557 10.0372 17.1934C9.94616 17.2312 9.84856 17.2506 9.75 17.2506C9.65144 17.2506 9.55384 17.2312 9.46279 17.1934C9.37175 17.1557 9.28903 17.1004 9.21937 17.0306L5.46938 13.2806C5.32864 13.1399 5.24958 12.949 5.24958 12.75C5.24958 12.551 5.32864 12.3601 5.46938 12.2194C5.61011 12.0786 5.80098 11.9996 6 11.9996C6.19902 11.9996 6.38989 12.0786 6.53063 12.2194L9.75 15.4397L18.2194 6.96937C18.3601 6.82864 18.551 6.74958 18.75 6.74958C18.949 6.74958 19.1399 6.82864 19.2806 6.96937C19.4214 7.11011 19.5004 7.30098 19.5004 7.5C19.5004 7.69902 19.4214 7.88989 19.2806 8.03063Z" />
      );

    case "duotone":
      return (
        <>
          <path
            opacity="0.2"
            d="M21.75 5.25V18.75C21.75 19.1478 21.592 19.5294 21.3107 19.8107C21.0294 20.092 20.6478 20.25 20.25 20.25H3.75C3.35218 20.25 2.97064 20.092 2.68934 19.8107C2.40804 19.5294 2.25 19.1478 2.25 18.75V5.25C2.25 4.85218 2.40804 4.47064 2.68934 4.18934C2.97064 3.90804 3.35218 3.75 3.75 3.75H20.25C20.6478 3.75 21.0294 3.90804 21.3107 4.18934C21.592 4.47064 21.75 4.85218 21.75 5.25Z"
          />
          <path d="M19.2807 8.03062L10.2807 17.0306C10.211 17.1003 10.1283 17.1557 10.0373 17.1934C9.94621 17.2312 9.84861 17.2506 9.75005 17.2506C9.65149 17.2506 9.55389 17.2312 9.46285 17.1934C9.3718 17.1557 9.28908 17.1003 9.21943 17.0306L5.46943 13.2806C5.3287 13.1399 5.24963 12.949 5.24963 12.75C5.24963 12.551 5.3287 12.3601 5.46943 12.2194C5.61016 12.0786 5.80103 11.9996 6.00005 11.9996C6.19907 11.9996 6.38995 12.0786 6.53068 12.2194L9.75005 15.4397L18.2194 6.96936C18.3602 6.82863 18.551 6.74957 18.7501 6.74957C18.9491 6.74957 19.1399 6.82863 19.2807 6.96936C19.4214 7.11009 19.5005 7.30097 19.5005 7.49999C19.5005 7.69901 19.4214 7.88988 19.2807 8.03062Z" />
        </>
      );
  }
};

const CheckIcon = forwardRef(SvgComponent);
CheckIcon.displayName = "CheckIcon";
export { CheckIcon };

import { Ref, SVGProps, forwardRef } from "react";

const SvgComponent = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 22 12"
    fill="currentColor"
    ref={ref}
    {...props}
  >
    <path d="M0.882353 12C0.647059 12 0.441177 11.91 0.264706 11.73C0.0882353 11.55 0 11.34 0 11.1V2.055H0.882353V11.1H7.85294V12H0.882353ZM2.64706 10.2C2.41176 10.2 2.20588 10.11 2.02941 9.93C1.85294 9.75 1.76471 9.54 1.76471 9.3V0.9C1.76471 0.66 1.85294 0.45 2.02941 0.27C2.20588 0.09 2.41176 0 2.64706 0H9.11765C9.35294 0 9.55882 0.09 9.7353 0.27C9.91177 0.45 10 0.66 10 0.9V9.3C10 9.54 9.91177 9.75 9.7353 9.93C9.55882 10.11 9.35294 10.2 9.11765 10.2H2.64706ZM2.64706 9.3H9.11765V0.9H2.64706V9.3Z" />
    <path d="M21.2059 12C21.4176 12 21.6029 11.91 21.7618 11.73C21.9206 11.55 22 11.34 22 11.1V2.055H21.2059V11.1H14.9324V12H21.2059ZM19.6176 10.2C19.8294 10.2 20.0147 10.11 20.1735 9.93C20.3324 9.75 20.4118 9.54 20.4118 9.3V0.9C20.4118 0.66 20.3324 0.45 20.1735 0.27C20.0147 0.09 19.8294 0 19.6176 0H13.7941C13.5824 0 13.3971 0.09 13.2382 0.27C13.0794 0.45 13 0.66 13 0.9V9.3C13 9.54 13.0794 9.75 13.2382 9.93C13.3971 10.11 13.5824 10.2 13.7941 10.2H19.6176ZM19.6176 9.3H13.7941V0.9H19.6176V9.3Z" />
    <path d="M5.60208 7.83832L3.19981 5.89333C3.07048 5.78862 2.99949 5.64878 3 5.49955C2.99949 5.35081 3.07048 5.21097 3.19981 5.10626L5.60107 3.16201C5.72999 3.05763 5.902 2.99984 6.08551 3C6.26892 3 6.44093 3.05714 6.56986 3.1616L6.98027 3.49313C7.1093 3.59751 7.18028 3.73545 7.18028 3.88394C7.18028 4.03252 7.1093 4.16906 6.98027 4.27352L6.43237 4.71174L11.314 4.71174C11.6917 4.71174 12 4.97113 12 5.27685V5.74681C12 6.05254 11.6917 6.29626 11.314 6.29626L6.46221 6.29626L6.98129 6.71898C7.11021 6.82344 7.1812 6.96517 7.1812 7.11375C7.1812 7.26224 7.11021 7.4015 6.98129 7.50588L6.57088 7.83832C6.44195 7.94261 6.27004 8 6.08653 8C5.90302 8 5.73101 7.94261 5.60208 7.83832Z" />
  </svg>
);

const ExploreQuizzesIcon = forwardRef(SvgComponent);
ExploreQuizzesIcon.displayName = "ExploreQuizzesIcon";
export { ExploreQuizzesIcon };

import { useEffect } from "react";

export function useFetchFirstGlobalSearchPageAfterIds({
  data,
  fetchNextPage,
}: {
  data: { pages: { nextCursor: number | null }[] } | undefined;
  fetchNextPage: () => void;
}) {
  useEffect(() => {
    if (data && data.pages.length === 1 && data.pages[0].nextCursor === 0) {
      fetchNextPage();
    }
  }, [data, fetchNextPage]);
}

import { getRootSF3URL } from "$lib/navigation/routing";

export function generateLTILink(url?: string): string {
  const rootSF3Url = getRootSF3URL();

  if (!url) {
    url = document.location.origin + document.location.pathname;
  }

  // assessment pages need groupId excluded
  url = url.replace(/(\/assess\/\d+)\/\d+$/, "$1");

  return url.replace(rootSF3Url, rootSF3Url + "/lti");
}

import { Ref, SVGProps, forwardRef } from "react";

const SvgComponent = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 15 20"
    fill="currentColor"
    ref={ref}
    {...props}
  >
    <path d="M1.56694 20C1.13602 20 0.767139 19.8431 0.460283 19.5293C0.153428 19.2154 0 18.8382 0 18.3975V8.29035C0 7.84809 0.153428 7.46951 0.460283 7.15458C0.767139 6.83964 1.13602 6.68218 1.56694 6.68218H3.0669V4.54785C3.0669 3.28763 3.49825 2.2146 4.36095 1.32876C5.22367 0.44292 6.26947 0 7.49837 0C8.72725 0 9.77267 0.44292 10.6346 1.32876C11.4966 2.2146 11.9276 3.28763 11.9276 4.54785V6.68218H13.4275C13.86 6.68218 14.2301 6.83964 14.5381 7.15458C14.846 7.46951 15 7.84809 15 8.29035V18.3975C15 18.8382 14.846 19.2154 14.5381 19.5293C14.2301 19.8431 13.86 20 13.4275 20H1.56694ZM1.56694 18.3975H13.4275V8.29035H1.56694V18.3975ZM7.5011 15.1551C7.98918 15.1551 8.40571 14.9823 8.7507 14.6368C9.09569 14.2914 9.26818 13.8761 9.26818 13.391C9.26818 12.9205 9.0944 12.4932 8.74683 12.109C8.39927 11.7248 7.98145 11.5327 7.49338 11.5327C7.0053 11.5327 6.58877 11.7248 6.24378 12.109C5.89879 12.4932 5.7263 12.9244 5.7263 13.4027C5.7263 13.881 5.90008 14.2926 6.24765 14.6376C6.59521 14.9826 7.01303 15.1551 7.5011 15.1551ZM4.63384 6.68218H10.3606V4.54914C10.3606 3.72765 10.0835 3.0321 9.52917 2.46252C8.97486 1.89295 8.29889 1.60817 7.50124 1.60817C6.7036 1.60817 6.02629 1.89295 5.46931 2.46252C4.91233 3.0321 4.63384 3.72765 4.63384 4.54914V6.68218Z" />
  </svg>
);

const LockIcon = forwardRef(SvgComponent);
LockIcon.displayName = "LockIcon";
export { LockIcon };

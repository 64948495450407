import { Ref, SVGProps, forwardRef } from "react";

const SvgComponent = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16"
    fill="currentColor"
    ref={ref}
    {...props}
  >
    <path d="M14.2069 5.01438L10.9856 1.79313C10.7975 1.60616 10.5433 1.50084 10.2781 1.5H5.72188C5.45667 1.50084 5.20247 1.60616 5.01438 1.79313L1.79313 5.01438C1.60616 5.20247 1.50084 5.45667 1.5 5.72188V10.2781C1.50084 10.5433 1.60616 10.7975 1.79313 10.9856L5.01438 14.2069C5.20247 14.3938 5.45667 14.4992 5.72188 14.5H10.2781C10.5433 14.4992 10.7975 14.3938 10.9856 14.2069L14.2069 10.9856C14.3938 10.7975 14.4992 10.5433 14.5 10.2781V5.72188C14.4992 5.45667 14.3938 5.20247 14.2069 5.01438ZM7.5 5C7.5 4.86739 7.55268 4.74021 7.64645 4.64645C7.74021 4.55268 7.86739 4.5 8 4.5C8.13261 4.5 8.25979 4.55268 8.35355 4.64645C8.44732 4.74021 8.5 4.86739 8.5 5V8.5C8.5 8.63261 8.44732 8.75979 8.35355 8.85355C8.25979 8.94732 8.13261 9 8 9C7.86739 9 7.74021 8.94732 7.64645 8.85355C7.55268 8.75979 7.5 8.63261 7.5 8.5V5ZM8 11.5C7.85166 11.5 7.70666 11.456 7.58332 11.3736C7.45999 11.2912 7.36386 11.1741 7.30709 11.037C7.25032 10.9 7.23547 10.7492 7.26441 10.6037C7.29335 10.4582 7.36478 10.3246 7.46967 10.2197C7.57456 10.1148 7.7082 10.0434 7.85368 10.0144C7.99917 9.98547 8.14997 10.0003 8.28701 10.0571C8.42406 10.1139 8.54119 10.21 8.6236 10.3333C8.70601 10.4567 8.75 10.6017 8.75 10.75C8.75 10.9489 8.67098 11.1397 8.53033 11.2803C8.38968 11.421 8.19891 11.5 8 11.5Z" />
  </svg>
);

const ErrorIcon = forwardRef(SvgComponent);
ErrorIcon.displayName = "ErrorIcon";
export { ErrorIcon };

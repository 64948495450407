import { Button } from "$components/shared/Button";
import { Checkbox } from "$components/shared/Form/Checkbox";
import {
  CourseFindOutput,
  useCourse,
} from "$hooks/useCourseNavigation/dataHooks";
import { getRootSF3URL } from "$lib/navigation/routing";
import { useEffect, useState } from "react";
import { tv } from "tailwind-variants";

const variants = tv({
  slots: {
    includesContainer:
      "grid grid-flow-row grid-cols-2 justify-center gap-x-12 gap-y-3 px-4",
  },
  variants: {
    isStudent: {
      true: { includesContainer: "grid-cols-3 justify-between" },
    },
  },
});

type PrintNotesProps = {
  isStudent: boolean;
  courseId: number;
  chapterId: number;
  canPrintEntireCourse: boolean;
  lessonId?: number;
  onCancelClick: () => void;
};

export const PrintNotesModalContent = ({
  isStudent,
  courseId,
  chapterId,
  canPrintEntireCourse,
  lessonId,
  onCancelClick,
}: PrintNotesProps) => {
  const [includes, setIncludes] = useState({
    videos: true,
    questions: true,
    readings: true,
    answers: !isStudent,
  });
  const [chapter, setChapter] = useState<
    NotNull<CourseFindOutput["chapters"]>[0] | null
  >(null);
  const [selectedLessonIds, setSelectedLessonIds] = useState<number[]>([]);
  const { data: course } = useCourse({
    courseId,
  });
  const style = variants({ isStudent });

  const onIncludesChecked = (name: keyof typeof includes) => {
    setIncludes((prev) => ({ ...prev, [name]: !prev[name] }));
  };
  const onSelectAll = () => {
    setSelectedLessonIds(chapter?.lessons.map((l) => l.id) || []);
  };
  const onSelectNone = () => {
    setSelectedLessonIds([]);
  };
  const onLessonChecked = (lessonId: number) => {
    setSelectedLessonIds((prev) =>
      prev.includes(lessonId)
        ? prev.filter((id) => id !== lessonId)
        : [...prev, lessonId]
    );
  };
  const onPrintPreviewClick = () => {
    const params: string[] = [];
    for (let key of Object.keys(includes)) {
      const paramName = key.slice(0, 1);
      if (isStudent && paramName === "a") {
        continue;
      }
      params.push(
        `${paramName}=${includes[key as keyof typeof includes] ? 1 : 0}`
      );
    }
    for (let lessonId of selectedLessonIds) {
      params.push(`l[]=${lessonId}`);
    }

    window.open(getRootSF3URL() + `/notes?${params.join("&")}`, "_blank");
  };

  const onPrintEntireCourseClick = () => {
    if (!canPrintEntireCourse) return;
    const params: string[] = [];
    for (let key of Object.keys(includes)) {
      const paramName = key.slice(0, 1);
      params.push(
        `${paramName}=${includes[key as keyof typeof includes] ? 1 : 0}`
      );
    }
    params.push(`c=${courseId}`);
    window.open(getRootSF3URL() + `/notes?${params.join("&")}`, "_blank");
  };

  useEffect(() => {
    if (!!course) {
      const chapter = (course as unknown as CourseFindOutput).chapters?.find(
        (c) => c.id === chapterId
      );
      if (!!chapter) {
        chapter.lessons.sort((l1, l2) => {
          return l1.number < l2.number ? -1 : 1;
        });
      }
      setChapter(chapter ?? null);
      setSelectedLessonIds(
        lessonId ? [lessonId] : chapter?.lessons.map((l) => l.id) || []
      );
    }
  }, [course, chapterId, lessonId]);

  return (
    <div id="print-notes-modal-content" className="contents">
      <div>
        <h3 className="mb-3 text-lg font-bold text-carbon-900">Include</h3>
        <div className={style.includesContainer()}>
          <Checkbox
            className="text-nowrap"
            label="Videos"
            checked={includes.videos}
            onChange={() => onIncludesChecked("videos")}
          />
          <Checkbox
            className="text-nowrap"
            label="Questions"
            checked={includes.questions}
            onChange={() => onIncludesChecked("questions")}
          />
          <Checkbox
            className="text-nowrap"
            label="Readings"
            checked={includes.readings}
            onChange={() => onIncludesChecked("readings")}
          />
          {!isStudent && (
            <Checkbox
              className="text-nowrap"
              label="Question Answers"
              checked={includes.answers}
              onChange={() => onIncludesChecked("answers")}
            />
          )}
        </div>
      </div>
      <h3 className="mb-3 text-lg font-bold text-carbon-900">
        Lessons of {chapter?.name || "Unknown Chapter"}
      </h3>
      <div className="flex min-h-56 shrink flex-col gap-4 overflow-y-auto rounded-lg border border-carbon-300 px-4 py-3">
        <div className="flex w-full flex-row flex-nowrap justify-start gap-4 text-carbon-100">
          <Button
            type="button"
            variant="subtle"
            size="sm"
            onClick={onSelectAll}
          >
            Select All
          </Button>
          <Button
            type="button"
            variant="subtle"
            size="sm"
            onClick={onSelectNone}
          >
            Select None
          </Button>
        </div>
        <div id="lesson-selections" className="flex flex-col gap-2">
          {chapter?.lessons.map((lesson) => (
            <Checkbox
              key={lesson.id}
              className="text-nowrap"
              label={lesson.name}
              checked={selectedLessonIds.includes(lesson.id)}
              onChange={() => onLessonChecked(lesson.id)}
            />
          ))}
        </div>
      </div>
      <div className="flex flex-row justify-center gap-4">
        <Button type="button" variant="subtle" onClick={onCancelClick}>
          Cancel
        </Button>
        {canPrintEntireCourse && (
          <Button
            type="button"
            variant="subtle"
            onClick={onPrintEntireCourseClick}
          >
            Print Full Course
          </Button>
        )}
        <Button
          type="button"
          onClick={onPrintPreviewClick}
          disabled={
            !selectedLessonIds.length ||
            Object.keys(includes).every(
              (key) => !includes[key as keyof typeof includes]
            )
          }
        >
          Print Preview
        </Button>
      </div>
    </div>
  );
};

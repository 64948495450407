import { portalContainer } from "$components/features/nav/NavBar/portalContainer";
import {
  AlertModalProps,
  DialogModalProps,
  ModalContent,
  ModalContentProps,
  ModalProps,
} from "$components/shared/ModalContent";
import { Dialog } from "radix-ui";
import { ReactNode, createContext, useContext, useState } from "react";
import { v4 as uuidv4 } from "uuid";

interface ProviderProps {
  children: ReactNode;
}

type IModal = DialogModalProps | AlertModalProps | ModalProps;

type ModalContextProps = {
  createAlert: (item: Omit<AlertModalProps, "type">) => string;
  createDialog: (item: Omit<DialogModalProps, "type">) => string;
  createModal: (item: Omit<ModalProps, "type">) => string;
  remove: (id: string) => void;
};

const ModalContext = createContext({} as ModalContextProps);

const useModal = () => {
  return useContext(ModalContext);
};

function ModalContextProvider({ children }: ProviderProps) {
  const [modals, setModals] = useState<ModalContentProps[]>([]);

  function createAlert(item: Omit<AlertModalProps, "type">) {
    const id = uuidv4();
    const payload: IModal & { id: string } = {
      ...item,
      id,
      type: "alert",
    };
    setModals((current) => [...current, payload]);

    return id;
  }

  function createDialog(item: Omit<DialogModalProps, "type">) {
    const id = uuidv4();
    const payload: IModal & { id: string } = {
      ...item,
      id,
      type: "dialog",
    };
    setModals((current) => [...current, payload]);

    return id;
  }

  function createModal(item: Omit<ModalProps, "type">) {
    const id = uuidv4();
    const payload: IModal & { id: string } = {
      ...item,
      id,
      type: "modal",
    };
    setModals((current) => [...current, payload]);

    return id;
  }

  function remove(id: string) {
    setModals((current) => {
      const list = current.filter((item) => item.id !== id) || [];
      return list;
    });
  }

  function popModal() {
    setModals((current) => {
      const list = current.slice(0, -1);
      return list;
    });
  }

  return (
    <ModalContext.Provider
      value={{
        createAlert,
        createDialog,
        createModal,
        remove,
      }}
    >
      {children}

      <Dialog.Root open={!!modals.length}>
        <Dialog.Portal container={portalContainer()}>
          <Dialog.Overlay
            onClick={popModal}
            className="fixed inset-0 z-40 grid h-screen w-screen place-items-center overflow-y-auto bg-carbon-200 opacity-80"
          />
          {modals.map((modal) => (
            <ModalContent key={modal.id} {...modal} />
          ))}
        </Dialog.Portal>
      </Dialog.Root>
    </ModalContext.Provider>
  );
}

export { ModalContextProvider, useModal };

/**
 * Converts the first character of string to upper case.
 */
export function upperFirst(str: string) {
  return str[0].toUpperCase() + str.substring(1);
}

export function generateRandomString(
  length = 10,
  uppercase = true,
  lowercase = true,
  numbers = true
) {
  let chars = "";
  if (uppercase) {
    chars += "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  }
  if (lowercase) {
    chars += "abcdefghijklmnopqrstuvwz";
  }
  if (numbers) {
    chars += "0123456789";
  }
  let str = "";
  for (let i = 0; i < length; i++) {
    str += chars[Math.floor(Math.random() * chars.length)];
  }
  return str;
}

let parser: DOMParser | undefined;
export function stripHtml(htmlString: string): string {
  if (typeof window === "undefined") return htmlString;

  if (!parser) parser = new DOMParser();

  const doc = parser.parseFromString(htmlString, "text/html");
  return doc.body.textContent || "";
}

export function titleCase(str: string) {
  return str.replace(
    /\w\S*/g,
    (txt) => txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase()
  );
}

export function snakeCaseToTitleCase(snakeCase: string) {
  return snakeCase
    .split("_")
    .map((n) => n.charAt(0).toUpperCase() + n.slice(1))
    .join(" ");
}

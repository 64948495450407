import { Ref, SVGProps, forwardRef } from "react";

const SvgComponent = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 27 27"
      width={27}
      fill="none"
      stroke="currentColor"
      ref={ref}
      {...props}
    >
      <circle strokeWidth={2} cx="13.5" cy="13.5" r="12.5" />
      <polygon points="21.9 13.5 8.8 6 8.8 21 21.9 13.5" />
    </svg>
  );
};

const SearchVideoIcon = forwardRef(SvgComponent);
SearchVideoIcon.displayName = "SearchVideoIcon";
export { SearchVideoIcon };

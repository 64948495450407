import { SearchInteractiveIcon } from "$components/shared/Icons/SearchInteractiveIcon";
import { SearchLessonIcon } from "$components/shared/Icons/SearchLessonIcon";
import { SearchQuestionIcon } from "$components/shared/Icons/SearchQuestionIcon";
import { SearchReadingIcon } from "$components/shared/Icons/SearchReadingIcon";
import { SearchVideoIcon } from "$components/shared/Icons/SearchVideoIcon";
import { LoadingBarOld } from "$components/shared/LoadingBarOld";
import { Spinner } from "$components/shared/Spinner";
import { usePreviousPersistent } from "$hooks/usePreviousPersistent";
import { getRootSF3URL } from "$lib/navigation/routing";
import { isSearchEnabled } from "$lib/search/isSearchEnabled";
import { type SearchEntity } from "@studyforge/schemas/search";
import cn from "classnames";
import { UseComboboxReturnValue } from "downshift";
import { useEffect, useRef } from "react";

export type SearchResult = {
  entity_type: SearchEntity["entity_type"];
  entity_id: number | string;
  name?: string;
  info: string;
};

export type SearchDropdownProps = {
  open: boolean;
  searchString: string;
  loading: boolean;
  results: SearchResult[];
  getMenuProps: UseComboboxReturnValue<SearchResult>["getMenuProps"];
  getItemProps: UseComboboxReturnValue<SearchResult>["getItemProps"];
  highlightedIndex: UseComboboxReturnValue<SearchResult>["highlightedIndex"];
  error: string | undefined;
  loadNextSearchResults: () => void;
};

export const SearchDropdown = ({
  open,
  searchString,
  loading,
  results,
  getMenuProps,
  getItemProps,
  highlightedIndex,
  error,
  loadNextSearchResults,
}: SearchDropdownProps) => {
  const previousSearchString = usePreviousPersistent(searchString);
  const searchStringToShow = loading ? previousSearchString : searchString;

  const observerRef = useRef<IntersectionObserver | null>(null);
  const { id: menuId, ...rest } = getMenuProps();

  useEffect(() => {
    const listElement = document.querySelector(`#${menuId}`);
    const itemsLength = results.length;
    const target = listElement?.children[itemsLength - 5];
    if (itemsLength < 15 || !listElement || !target) return;

    observerRef.current = new IntersectionObserver(
      (entries: any) => {
        entries.forEach((entry: any) => {
          if (entry.isIntersecting) {
            loadNextSearchResults();
          }
        });
      },
      {
        root: listElement,
        threshold: 1.0,
      }
    );

    // Select and observe the fifth item from the end
    observerRef.current.observe(target);

    // Cleanup observer on component unmount
    return () => {
      observerRef.current?.disconnect();
    };
  }, [loadNextSearchResults, menuId, results.length]);

  return (
    <div
      data-testid="nav-search-dropdown"
      className={cn(
        "absolute left-0 right-0 top-full min-w-[300px] bg-white py-2 shadow-md",
        !open && "hidden"
      )}
    >
      <LoadingBarOld loading={loading} />
      <ul id={menuId} {...rest} className="max-h-[562px] overflow-y-auto">
        {results.length === 0 ? (
          <p className="my-2 ml-4 mr-3 line-clamp-2 text-base">
            {error ? (
              <span>{error}</span>
            ) : (
              <>
                <span>
                  {searchStringToShow && isSearchEnabled(searchString)
                    ? `No results for`
                    : "Search lessons and learning objects"}
                </span>
                {searchStringToShow && isSearchEnabled(searchString) && (
                  <>
                    <br />
                    <span>{` "${searchStringToShow}"`}</span>
                  </>
                )}
              </>
            )}
          </p>
        ) : (
          results.map((result, index) => (
            <SearchItem
              key={result.entity_type + result.entity_id}
              result={result}
              index={index}
              getItemProps={getItemProps}
              highlighted={highlightedIndex === index}
            />
          ))
        )}
        {loading && results.length > 0 && (
          <div className="flex items-center justify-center">
            <Spinner size="sm" className="opacity-50" />
          </div>
        )}
      </ul>
    </div>
  );
};

const SearchItem = ({
  result,
  index,
  getItemProps,
  highlighted,
}: {
  result: SearchResult;
  index: number;
  getItemProps: UseComboboxReturnValue<SearchResult>["getItemProps"];
  highlighted: boolean;
}) => {
  return (
    <li
      className={cn(
        "flex h-[52px] cursor-pointer items-center",
        highlighted && "bg-[#ECEDED]"
      )}
      {...getItemProps({ item: result, index })}
    >
      <a
        href={`${getRootSF3URL()}/${result.entity_type}/${result.entity_id}`}
        className="navbar-search-result flex h-full w-full items-center pr-5"
        target="_self"
        rel="noopener noreferrer"
      >
        <div className="flex w-14 shrink-0 items-center justify-center">
          <SearchItemIcon type={result.entity_type} />
        </div>

        <div className="overflow-hidden">
          <p className="truncate text-[10.5px] italic">{result.info}</p>
          <p className="truncate text-xs">{result.name}</p>
        </div>
      </a>
    </li>
  );
};

const SearchItemIcon = ({ type }: { type: SearchResult["entity_type"] }) => {
  switch (type) {
    case "video":
      return <SearchVideoIcon className="text-carbon-800" width={32} />;

    case "question":
      return <SearchQuestionIcon className="text-carbon-800" width={32} />;

    case "reading":
      return <SearchReadingIcon className="text-carbon-800" width={32} />;

    case "interactive":
      return <SearchInteractiveIcon className="text-carbon-800" width={32} />;

    case "lesson":
    case "project":
    case "chapter":
    case "course":
      return <SearchLessonIcon className="text-carbon-800" width={32} />;
  }
};
